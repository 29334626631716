import { AxiosResponse } from 'axios'
import { Account } from '@jarvis/web-stratus-client'
import { IStratusService } from '../../lib/stratusService'
import { MockFixtures } from './mockFixtures'

const userAccountFixture = (): Account => ({
  type: 'Personal',
  language: 'en',
  idToken: 'some-token',
  countrySet: ['US']
})

export class MockStratusService implements IStratusService {
  private mockFixtures?: MockFixtures
  private defaultDelay: number

  constructor(mockFixtures?: MockFixtures, defaultDelay = 2000) {
    this.mockFixtures = mockFixtures
    this.defaultDelay = defaultDelay
  }

  private mockFixtureOverride(name: string): object {
    return this.mockFixtures?.getFixture(name) || {}
  }

  private resolve<T>(
    result: AxiosResponse<T>,
    delay: number = this.defaultDelay
  ): Promise<AxiosResponse<T>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }

  getUserAccount() {
    return this.resolve<Account>({
      data: {
        ...userAccountFixture(),
        ...this.mockFixtureOverride('userAccount')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
