import { ShippingAddress } from '../../types'

export const shippingAddressFixture = ({
  firstName = 'Bob',
  lastName = 'Smith',
  company = 'HP Inc',
  street1 = '456 Secondary St',
  street2 = 'APT 2',
  city = 'Los Angeles',
  state = 'CA',
  zipCode = '92127',
  phoneNumber1 = '0987654321',
  countryCode = 'US'
} = {}): ShippingAddress => ({
  firstName,
  lastName,
  company,
  street1,
  street2,
  city,
  state,
  zipCode,
  phoneNumber1,
  countryCode
})
