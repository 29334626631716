import { Settings } from '../../types'

export const settingsFixture = ({
  enablePgsHostedFormV2 = true,
  enableDirectDebitAllPaymentFormV2 = false,
  enableBillingAccountType = true,
  enableVatExamples = true,
  billingAccountTypeFields = {
    business: {
      required: ['companyName', 'pecEmailOrRecipientCode', 'nonProfitTaxId'],
      optional: ['taxId']
    },
    consumer: {
      required: [],
      optional: ['taxId']
    }
  },
  enableVerifoneBillingAddress = false,
  enableSpainTaxIdValidation = false,
  enableBillingUpdatedLayout = false,
  enableVerifoneCombineStreets = false
} = {}): Settings => ({
  enablePgsHostedFormV2,
  enableDirectDebitAllPaymentFormV2,
  enableBillingAccountType,
  enableVatExamples,
  billingAccountTypeFields,
  enableVerifoneBillingAddress,
  enableSpainTaxIdValidation,
  enableBillingUpdatedLayout,
  enableVerifoneCombineStreets
})
