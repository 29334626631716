export enum AccountType {
  business = 'business',
  consumer = 'consumer'
}

export type TaxpayerDetails = {
  accountType?: AccountType
  lockedFields?: string
  companyName?: string
  taxId?: string
  nonProfitTaxId?: string
  pecEmailOrRecipientCode?: string
}
