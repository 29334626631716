import { twoCheckoutScriptUrl } from '../lib/url'
import { useScript } from './useScript'
import { TwoCheckout } from '../types'

let define_amd_value: unknown = undefined

const undefine_amd = () => {
  if (window['define']?.amd) {
    define_amd_value = window['define'].amd
    window['define'].amd = undefined
  }
}

const restore_amd = () => {
  if (define_amd_value && window['define']) {
    window['define'].amd = define_amd_value
    define_amd_value = undefined
  }
}

export const useTwoCheckout = (): TwoCheckout => {
  const { status, windowObject } = useScript(
    'TwoCoInlineCart',
    twoCheckoutScriptUrl,
    undefine_amd,
    restore_amd
  )
  const twoCoInlineCart = windowObject
    ? (windowObject as TwoCheckout['twoCoInlineCart'])
    : undefined

  return { status, twoCoInlineCart }
}
