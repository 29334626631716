import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchBillingInfoAction } from '../actions'

export const useBillingInfoLoading = () =>
  useBillingFormState().billingInfoLoading

export const useBillingInfoError = () => useBillingFormState().billingInfoError

export const useBillingInfo = () => {
  const { billingInfo, billingInfoLoading, billingInfoError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (billingInfo === undefined && !billingInfoLoading && !billingInfoError) {
      ;(async () => {
        try {
          await dispatch(fetchBillingInfoAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [billingInfo, billingInfoLoading, billingInfoError, dispatch])

  return billingInfo
}
