import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchUserAccountAction } from '../actions'
import { Account } from '@jarvis/web-stratus-client'

export const useUserAccountLoading = () =>
  useBillingFormState().userAccountLoading

export const useUserAccountError = () => useBillingFormState().userAccountError

export const useUserAccount = (): Account | undefined => {
  const { userAccount, userAccountLoading, userAccountError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (userAccount === undefined && !userAccountLoading && !userAccountError) {
      ;(async () => {
        try {
          await dispatch(fetchUserAccountAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [userAccount, userAccountLoading, userAccountError, dispatch])

  return userAccount
}
