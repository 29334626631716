import { postcodeValidator } from 'postcode-validator'

export type ValidateFieldOptions = {
  accountType?: string
  required?: boolean
  useValidationScript?: boolean
}

export class AddressValidator {
  country: string

  constructor(country: string) {
    this.country = country
  }

  validateField(
    field: string,
    value?: string,
    options: ValidateFieldOptions = {}
  ) {
    let valid = false

    switch (field) {
      case 'firstName':
      case 'lastName':
        valid = this.validateName(value)
        break
      case 'company':
        valid = this.validateCompany(value, options.accountType)
        break
      case 'street1':
        valid = this.validateStreet(value)
        break
      case 'street2':
        valid = !value || this.validateStreet(value)
        break
      case 'city':
        valid = this.validateCity(value)
        break
      case 'state':
        valid = this.validateState(value)
        break
      case 'zipCode':
        valid = this.validateZipCode(value)
        break
      case 'companyName':
        valid = this.validateCompanyName(value, Boolean(options.required))
        break
      case 'taxId':
        valid = this.validateTaxId(
          value,
          Boolean(options.required),
          options.accountType as string,
          Boolean(options.useValidationScript)
        )
        break
      case 'nonProfitTaxId':
        valid = this.validateNpoTaxId(value, Boolean(options.required))
        break
      case 'pecEmailOrRecipientCode':
        valid = this.validateInvoiceChannel(value, Boolean(options.required))
        break
    }

    return valid && !this.containsCreditCardInfo(value)
  }

  private containsCreditCardInfo(string?: string): boolean {
    const value = string ? string.trim() : ''
    return (
      value.length > 12 &&
      !!value
        .replace(/\D+/g, ',')
        .split(',')
        .find((token) => this.isCreditCard(token))
    )
  }

  private isCreditCard(string: string): boolean {
    return /^[2-6]/.test(string) && [13, 15, 16].includes(string.length)
  }

  private validateTaxId(
    string: string | undefined,
    required: boolean,
    accountType: string,
    useValidationScript: boolean
  ): boolean {
    const value = string ? string.trim() : ''
    const emptyValue = !value

    if (required && emptyValue) return false
    if (emptyValue) return true

    const isBusiness = accountType === 'business'

    switch (this.country) {
      case 'AT':
        return /^(at)?u\d{8}$/i.test(value)
      case 'BE': // required for business
        return /^(be)?\d{8}[a-z\d]{2}$/i.test(value)
      case 'BG':
        return /^\d{9,10}$/.test(value)
      case 'CA':
        return isBusiness
          ? /^\d{9}$/.test(value)
          : /^\d{3}-\d{3}-\d{3}$/.test(value)
      case 'CH':
        return /^[a-z0-9-:. ]{14,25}$/i.test(value)
      case 'CY':
        return /^\d{8}[a-z]$/i.test(value)
      case 'CZ':
        return /^\d{8,10}$/.test(value)
      case 'DE':
        return /^(de)?\d{9}$/i.test(value)
      case 'DK':
        return /^(dk)?\d{8}$/i.test(value)
      case 'ES': // required for business
        if (useValidationScript) {
          return this.isValidNIF(value) || this.isValidCIF(value)
        }
        return /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/.test(value)
      case 'FI':
        return /^(fi)?\d{8}$/i.test(value)
      case 'FR':
        return /^(fr)?[a-z0-9]{2}\d{9}$/i.test(value)
      case 'GB':
        return /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/.test(value)
      case 'IE':
        return /^((ie)?\d{7}[a-z]|(ie)?\d{7}[a-z]w)$/i.test(value)
      case 'IT': // required for business and consumer
        return isBusiness
          ? this.vatCheck(value)
          : this.codiceFiscaleCheck(value)
      case 'LU':
        return /^(lu)?\d{8}$/i.test(value)
      case 'NL':
        return /^(nl)?\d{9}b\d{2}$/i.test(value)
      case 'NO': // required for business
        return /^\d{9}mva$/i.test(value)
      case 'PT': // required for business
        return isBusiness
          ? /^[569]\d{8}$/.test(value)
          : /^[0-3]\d{8}$/.test(value)
      case 'SE':
        return /^(se)?\d{12}$/i.test(value)
      case 'US':
      case 'PR':
        return /^\d{2}-\d{7}$/.test(value)
      case 'HU':
      case 'MT':
      case 'SI':
        return /^\d{8}$/.test(value)
      case 'EE':
      case 'GR':
        return /^\d{9}$/.test(value)
      case 'PL':
      case 'SK':
        return /^\d{10}$/.test(value)
      case 'AU':
      case 'HR':
        return /^\d{11}$/.test(value)
      case 'LT':
      case 'LV':
        return /^\d{9,12}$/.test(value)
      case 'NZ':
        return /^\d{1,12}$/.test(value)
      case 'RO':
        return /^\d{2,10}$/.test(value)
      case 'SG':
        return /^\d{1,12}[a-z]$/i.test(value)
      default:
        return true
    }
  }

  private validateNpoTaxId(string: string | undefined, required: boolean) {
    const value = string ? string.trim() : ''
    const emptyValue = value.length === 0

    if (required && emptyValue) return false

    if (emptyValue) return true

    if (this.country === 'IT') {
      return this.vatCheck(value)
    } else {
      return true
    }
  }

  private validateInvoiceChannel(
    string: string | undefined,
    required: boolean
  ) {
    const value = string ? string.trim() : ''
    const emptyValue = value.length === 0

    if (required && emptyValue) return false

    if (emptyValue) return true

    if (this.country === 'IT') {
      const validCode = /^(?!0{7})[a-z\d]{7}$/i.test(value)

      const noSpecialCharacters = '[^<>()[\\]\\\\.,;:\\s@"]'
      const validEmail = new RegExp(
        `^(((${noSpecialCharacters}+(\\.${noSpecialCharacters}+)*)|(".+"))@((\\[(\\d{1,3}\\.){3}\\d{1,3}])|(([a-z\\-\\d]+\\.)+[a-z]{2,})))$`,
        'i'
      ).test(value)

      return validCode || validEmail
    } else {
      return true
    }
  }

  private validateName(string?: string): boolean {
    const value = string ? string.trim() : ''
    return (
      value.length >= 1 &&
      value.length <= 30 &&
      /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?0-9]+$/i.test(value)
    )
  }

  private validateCompany(string?: string, accountType?: string) {
    const value = string ? string.trim() : ''
    const valid =
      value.length <= 50 && /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/i.test(value)

    if (accountType === 'business' && this.country === 'PT') {
      return valid
    }

    return value.length === 0 || valid
  }

  private validateCompanyName(string: string | undefined, required: boolean) {
    const value = string ? string.trim() : ''
    const emptyValue = !value

    if (required && emptyValue) return false
    if (emptyValue) return true

    const validLength = value.length <= 50
    const validFormat = /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/i.test(value)

    return validLength && validFormat
  }

  private validateStreet(string?: string) {
    const value = string ? string.trim() : ''
    return (
      value.length >= 1 &&
      value.length <= 30 &&
      /^[^~_!@$%^*\\+=[\];{}|<>?]+$/i.test(value)
    )
  }

  private validateCity(string?: string) {
    const value = string ? string.trim() : ''
    let match

    if (this.country === 'IE') {
      match = /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?]+$/i.test(value)
    } else {
      match = /^[^`~_!@#$%^*()+=[\]\\/;{}|:<>?0-9]+$/i.test(value)
    }

    return value.length >= 2 && value.length <= 50 && match
  }

  private validateState(string?: string) {
    const value = string ? string.trim() : ''
    return value.length > 0
  }

  private validateGBZipCode(string: string) {
    const firstChar = '[ABCDEFGHIJKLMNOPRSTUWYZ]' // Does not accept QVX
    const secondChar = '[ABCDEFGHKLMNOPQRSTUVWXY]' // Does not accept IJZ
    const thirdChar = '[ABCDEFGHJKPMNRSTUVWXY]' // Does not accept ILOZ
    const fourthChar = '[ABEHMNPRVWXY]' // Does not accept CDFGIJKOQSTUZ
    const fifthChar = '[ABDEFGHJLNPQRSTUWXYZ]' // Does not accept CIKMOV
    const regexps = [
      // AN NAA, ANN NAA, AAN NAA, AANN NAA
      new RegExp(
        `^(${firstChar}${secondChar}?\\d{1,2})(\\s*)(\\d${fifthChar}{2})$`,
        'i'
      ),
      // ANA NAA
      new RegExp(
        `^(${firstChar}\\d${thirdChar})(\\s*)(\\d${fifthChar}{2})$`,
        'i'
      ),
      // AANA NAA
      new RegExp(
        `^(${firstChar}${secondChar}?\\d${fourthChar})(\\s*)(\\d${fifthChar}{2})$`,
        'i'
      ),
      /^(BF1)(\s*)([0-6][ABDEFGHJLNPQRST][ABDEFGHJLNPQRSTUWYZ])$/i, // BFPO postcodes
      /^(GIR)(\s*)(0AA)$/i, // Special postcode GIR 0AA
      /^(BFPO)(\s*)(\d{1,4})$/i, // Standard BFPO numbers
      /^(BFPO)(\s*)(c\/o\s*\d{1,3})$/i, // c/o BFPO numbers
      /^([A-Z]{4})(\s*)(1ZZ)$/i, // Overseas Territories
      /^(AI-2640)$/i // Anguilla
    ]

    return !!regexps.find((regexp) => regexp.test(string))
  }

  private validateZipCode(string?: string) {
    const { country } = this
    const value = string ? string.trim().toUpperCase() : ''

    switch (country) {
      case 'AT':
      case 'BE':
        return /^([1-9])(\d{3})$/.test(value)
      case 'CA':
        return /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]\s?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i.test(
          value
        )
      case 'CH':
        return /^\d{4}$/.test(value)
      case 'DE':
        return /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/.test(value)
      case 'DK':
        return /^(DK(-|\s)?)?\d{4}$/i.test(value)
      case 'ES':
        return /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(value)
      case 'FI':
        return /^(FI-)?\d{5}$/i.test(value)
      case 'FR':
        return /^\d{5}$/i.test(value)
      case 'GB':
        return this.validateGBZipCode(value)
      case 'IE':
        return (
          value.length === 0 ||
          /^(D6W|[ACDEFHKNPRTVWXY]\d{2})\s?[0-9ACDEFHKNPRTVWXY]{4}$/.test(value)
        )
      case 'IT':
        return /^(I-|IT-)?\d{5}$/i.test(value)
      case 'LU':
        return /^([Ll]-)?[1-9](\d{3})$/.test(value)
      case 'NL':
        return /^[1-9]\d{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(value)
      case 'NO':
        return /^(N-|NO-)?\d{4}$/i.test(value)
      case 'PR':
        return postcodeValidator(value, country) && /^00[679]/.test(value)
      case 'PT':
        return /^[1-9]\d{3}-\d{3}$/.test(value)
      case 'SE':
        return /^(SE-)?\d{3}\s?\d{2}$/i.test(value)
      case 'US':
        return /^\d{5}(-?\d{4})?$/.test(value)
      default:
        return postcodeValidator(value, country)
    }
  }

  private vatCheck(string: string) {
    if (!/^(\d{11})$/.test(string)) {
      return false
    }

    const checkSum = Array.from(string.substring(0, 10)).reduce(
      (acc, char, index) => {
        let digit = parseInt(char)
        if (index % 2 === 1) {
          digit = digit * 2
          if (digit > 9) {
            digit -= 9
          }
        }
        return acc + digit
      },
      0
    )

    const checkDigit = (100 - checkSum) % 10
    return parseInt(string[10]) === checkDigit
  }

  private codiceFiscaleCheck(string: string) {
    const months = {
      A: ['Jan', 31],
      B: ['Feb', 28],
      C: ['Mar', 31],
      D: ['Apr', 30],
      E: ['May', 31],
      H: ['Jun', 30],
      L: ['Jul', 31],
      M: ['Aug', 31],
      P: ['Sep', 30],
      R: ['Oct', 31],
      S: ['Nov', 30],
      T: ['Dec', 31]
    }
    const characterValues = {
      '0': [1, 0],
      '1': [0, 1],
      '2': [5, 2],
      '3': [7, 3],
      '4': [9, 4],
      '5': [13, 5],
      '6': [15, 6],
      '7': [17, 7],
      '8': [19, 8],
      '9': [21, 9],
      A: [1, 0],
      B: [0, 1],
      C: [5, 2],
      D: [7, 3],
      E: [9, 4],
      F: [13, 5],
      G: [15, 6],
      H: [17, 7],
      I: [19, 8],
      J: [21, 9],
      K: [2, 10],
      L: [4, 11],
      M: [18, 12],
      N: [20, 13],
      O: [11, 14],
      P: [3, 15],
      Q: [6, 16],
      R: [8, 17],
      S: [12, 18],
      T: [14, 19],
      U: [16, 20],
      V: [10, 21],
      W: [22, 22],
      X: [25, 23],
      Y: [24, 24],
      Z: [23, 25]
    }
    const checkCharacter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const numberCharacters = {
      L: 0,
      M: 1,
      N: 2,
      P: 3,
      Q: 4,
      R: 5,
      S: 6,
      T: 7,
      U: 8,
      V: 9,
      '0': 0,
      '1': 1,
      '2': 2,
      '3': 3,
      '4': 4,
      '5': 5,
      '6': 6,
      '7': 7,
      '8': 8,
      '9': 9
    }

    const taxId = string.toUpperCase()

    if (taxId.length !== 16) {
      return false
    }

    if (
      !/^([A-Z]{6}[L-NP-V0-9]{2}[A-EHLMPR-T][L-NP-V0-9]{2}[A-Z][L-NP-V0-9]{3}[A-Z])$/.test(
        taxId
      )
    ) {
      return false
    }

    const birthYear =
      numberCharacters[taxId[6]] * 10 + numberCharacters[taxId[7]]

    const birthMonth = months[taxId[8]][0]
    let birthMonthDays = months[taxId[8]][1]
    if (birthMonth === 'Feb' && birthYear % 4 === 0) {
      birthMonthDays += 1
    }

    let birthDay = numberCharacters[taxId[9]] * 10 + numberCharacters[taxId[10]]
    if (birthDay >= 40) {
      birthDay -= 40
    }

    if (birthDay < 1 || birthDay > birthMonthDays) {
      return false
    }

    let checkTotal = 0
    for (let x = 0; x < 15; x++) {
      if (x % 2 === 0) {
        checkTotal += characterValues[taxId[x]][0]
      } else {
        checkTotal += characterValues[taxId[x]][1]
      }
    }
    const checkDigit = checkCharacter[checkTotal % 26]

    return taxId[15] === checkDigit
  }

  private isValidNIF(nif: string): boolean {
    const regex = /^[XYZ]?\d{7,8}[A-Z]$/i
    if (!regex.test(nif)) {
      return false
    }
    nif = nif.toUpperCase()

    const initialLetter = nif.charAt(0)
    switch (initialLetter) {
      case 'X':
        nif = nif.replace('X', '0')
        break
      case 'Y':
        nif = nif.replace('Y', '1')
        break
      case 'Z':
        nif = nif.replace('Z', '2')
        break
    }

    const numberPart = nif.substring(0, nif.length - 1)
    const checkLetter = nif.charAt(nif.length - 1)
    const validLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'
    const calculatedLetter = validLetters.charAt(parseInt(numberPart, 10) % 23)

    return checkLetter === calculatedLetter
  }

  private isValidCIF(cif: string): boolean {
    const regex = /^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$/i
    if (!regex.test(cif)) {
      return false
    }
    cif = cif.toUpperCase()

    const controlDigit = cif.slice(-1)
    const body = cif.slice(1, -1)
    let evenSum = 0,
      oddSum = 0

    for (let i = 0; i < body.length; i++) {
      const n = parseInt(body[i], 10)
      if (i % 2 === 1) {
        evenSum += n
      } else {
        const m = 2 * n
        const sumDigits = m > 9 ? Math.floor(m / 10) + (m % 10) : m
        oddSum += sumDigits
      }
    }

    const totalSum = evenSum + oddSum
    const checksum = (10 - (totalSum % 10)) % 10
    let validControlDigit
    if (['A', 'B', 'E', 'H'].includes(cif[0])) {
      validControlDigit = checksum.toString()
    } else {
      const letters = 'JABCDEFGHI'
      validControlDigit = letters[checksum]
      if (validControlDigit !== controlDigit) {
        validControlDigit = checksum.toString()
      }
    }

    return controlDigit === validControlDigit
  }
}
