import React, { FC } from 'react'

export const DirectDebit: FC<{ ['data-testid']: string }> = (props) => (
  <svg
    data-testid={props['data-testid']}
    xmlns="http://www.w3.org/2000/svg"
    width="71px"
    height="20px"
    viewBox="0 0 71 20"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#0B4A8E"
        d="M16.269 6.238H11.53c0-.777-.091-1.3-.273-1.565-.282-.393-1.06-.59-2.334-.59-1.237 0-2.056.114-2.457.34-.4.228-.6.722-.6 1.483 0 .689.178 1.142.533 1.36.255.154.59.245 1.01.272l.956.067c2.047.136 3.326.232 3.836.286 1.62.163 2.793.594 3.522 1.292.573.544.915 1.256 1.024 2.135.063.525.095 1.12.095 1.781 0 1.523-.145 2.639-.436 3.346-.528 1.297-1.713 2.116-3.556 2.46-.773.146-1.955.219-3.544.219-2.653 0-4.502-.158-5.546-.476-1.282-.388-2.126-1.176-2.535-2.363-.228-.66-.34-1.76-.34-3.3h4.737v.394c0 .822.237 1.35.708 1.585.326.163.72.248 1.185.258h1.741c.889 0 1.456-.046 1.7-.137.436-.171.722-.447.859-.828.072-.235.108-.54.108-.911 0-.833-.304-1.34-.911-1.522-.227-.072-1.283-.172-3.169-.299-1.514-.108-2.566-.212-3.155-.312-1.551-.29-2.585-.887-3.1-1.793-.454-.77-.68-1.934-.68-3.492 0-1.186.122-2.138.367-2.853.245-.716.64-1.26 1.185-1.63C3.26.873 4.281.538 5.526.438A39.913 39.913 0 0 1 8.972.302c1.988 0 3.405.115 4.25.341 2.062.554 3.093 2.106 3.093 4.655 0 .21-.016.522-.046.94M42.194 9.85h3.175c1.006-.037 1.619-.114 1.84-.236.3-.165.502-.496.603-.993.064-.331.096-.823.096-1.476 0-.8-.064-1.394-.192-1.781-.183-.543-.622-.873-1.317-.993-.137-.018-.466-.028-.987-.028h-3.218v5.506zm-5.092 9.274V.202h9.487c1.302 0 2.295.107 2.977.319 1.56.489 2.608 1.49 3.144 3.003.277.794.415 1.99.415 3.586 0 1.919-.154 3.298-.457 4.138-.609 1.66-1.861 2.617-3.753 2.865-.222.037-1.169.07-2.84.097l-.845.028h-3.036v4.886h-5.092zM62.984 12.167l-2.386-8.202-2.315 8.202h4.701zm1.011 3.687h-6.808l-.915 3.27h-5.269L56.688.202h7.682l5.755 18.922h-5.158l-.972-3.27z"
      />
      <path
        fill="#F6BC25"
        d="M30.073 2.864c2.1 0 3.975.968 5.207 2.483l1.098-2.353A9.623 9.623 0 0 0 29.748.37c-4.013 0-7.446 2.423-8.854 5.854h-2.141l-1.264 2.71h2.754c-.02.25-.034.502-.034.759 0 .31.016.617.047.92h-1.384l-1.264 2.711h3.351c1.45 3.344 4.839 5.691 8.788 5.691a9.65 9.65 0 0 0 5.367-1.615v-3.322a6.692 6.692 0 0 1-5.04 2.288 6.707 6.707 0 0 1-5.615-3.042h7.017l1.264-2.71h-9.314a6.86 6.86 0 0 1-.04-1.68h10.137l1.264-2.71H24.263a6.71 6.71 0 0 1 5.81-3.36"
      />
      <path
        fill="#0B4A8E"
        d="M24.263 6.306h10.524l-.074-.116L33.45 8.9l.073-.047h-10.21l-.008.074a6.993 6.993 0 0 0 .04 1.7l.011.07h9.384l-.073-.116-1.264 2.71.073-.047h-7.168l.083.125a6.78 6.78 0 0 0 5.682 3.078c1.95 0 3.81-.843 5.103-2.314l-.143-.054v3.322l.036-.067a9.566 9.566 0 0 1-5.322 1.6c-3.808 0-7.228-2.214-8.713-5.642l-.021-.048h-3.405l.074.116 1.264-2.711-.074.047h1.473l-.009-.09a9.247 9.247 0 0 1-.046-.912c0-.23.011-.476.034-.753l.007-.088h-2.842l.073.116 1.264-2.71-.073.047h2.195l.02-.05a9.283 9.283 0 0 1 3.421-4.177A9.555 9.555 0 0 1 29.747.453c2.466 0 4.8.923 6.575 2.6l-.018-.094-1.097 2.354.136-.017a6.77 6.77 0 0 0-5.27-2.513 6.824 6.824 0 0 0-5.881 3.4l-.07.123h.141zm5.81-3.36c2.002 0 3.876.894 5.144 2.453l.082.1.055-.117 1.098-2.354.024-.053-.042-.04A9.69 9.69 0 0 0 29.747.29 9.713 9.713 0 0 0 24.3 1.944a9.438 9.438 0 0 0-3.48 4.25l.075-.05H18.7l-.022.046-1.317 2.826h2.88l-.08-.088a9.28 9.28 0 0 0-.034.766c0 .304.015.617.046.929l.082-.09H18.82l-.022.048-1.264 2.71-.053.115h3.478l-.074-.049c1.51 3.487 4.99 5.74 8.862 5.74a9.724 9.724 0 0 0 5.412-1.628l.036-.024v-3.582l-.142.162a6.62 6.62 0 0 1-10.527-.746l-.067.127h7.07l.02-.046 1.265-2.711.054-.115h-9.442l.08.069a6.812 6.812 0 0 1-.038-1.66l-.082.073h10.19l.021-.047 1.264-2.71.054-.116H24.263l.07.122a6.661 6.661 0 0 1 5.74-3.32z"
      />
    </g>
  </svg>
)
