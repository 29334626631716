import React, { FC } from 'react'
import {
  MOBILE_CONTAINER,
  useBillingAddress,
  useContainerSize,
  useDispatch,
  useErrorFields,
  useGetText,
  useSettings,
  useStateOptions
} from '../../hooks'
import { validateBillingAddressAction } from '../../actions'
import {
  StyledFormFields,
  StyledInputGroup,
  StyledSelect,
  StyledTextBox
} from './styles'
import { SelectOption } from '@veneer/core/dist/scripts/select'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

type AddressFormProps = {
  showStateDropdown: boolean
  shippingChecked: boolean
}

export const AddressForm: FC<AddressFormProps> = ({
  showStateDropdown,
  shippingChecked
}) => {
  const billingAddress = useBillingAddress()
  const { enableBillingAccountType } = useSettings() || {}
  const stateOptions = useStateOptions()
  const dispatch = useDispatch()
  const errorFields = useErrorFields()
  const getText = useGetText()
  const containerSize = useContainerSize()

  const {
    firstName,
    lastName,
    company,
    street1,
    street2,
    city,
    state,
    zipCode,
    countryCode
  } = billingAddress || {}

  const showErrorForField = (field: string) => errorFields.has(field)

  const onChangeInput = (field: string) => (value: string) =>
    dispatch(
      validateBillingAddressAction({
        field,
        value
      })
    )

  const onChangeSelect = (field: string) => (option: SelectOption) =>
    dispatch(
      validateBillingAddressAction({
        field,
        value: option.value.toString()
      })
    )

  return (
    <VeneerThemeProvider shape="round">
      <StyledFormFields>
        <StyledInputGroup>
          <StyledTextBox
            id="first-name"
            data-testid="first-name-input-field"
            name="firstName"
            label={getText('billing_address.first_name')}
            value={firstName || ''}
            onChange={onChangeInput('firstName')}
            error={showErrorForField('firstName')}
            required
            disabled={shippingChecked}
          />
          <StyledTextBox
            id="last-name"
            data-testid="last-name-input-field"
            name="lastName"
            label={getText('billing_address.last_name')}
            value={lastName || ''}
            onChange={onChangeInput('lastName')}
            error={showErrorForField('lastName')}
            required
            disabled={shippingChecked}
          />
        </StyledInputGroup>
        <StyledTextBox
          id="street1"
          name="street1"
          label={getText('billing_address.street1')}
          value={street1 || ''}
          onChange={onChangeInput('street1')}
          error={showErrorForField('street1')}
          required
          disabled={shippingChecked}
        />
        <StyledTextBox
          id="street2"
          name="street2"
          label={getText('billing_address.street2')}
          value={street2 || ''}
          onChange={onChangeInput('street2')}
          error={showErrorForField('street2')}
          disabled={shippingChecked}
        />
        {!enableBillingAccountType ? (
          <StyledTextBox
            id="company"
            name="company"
            label={getText('billing_address.company')}
            value={company || ''}
            onChange={onChangeInput('company')}
            error={showErrorForField('company')}
            disabled={shippingChecked}
          />
        ) : null}
        <StyledInputGroup>
          <StyledTextBox
            id="city"
            name="city"
            label={getText('billing_address.city')}
            value={city || ''}
            onChange={onChangeInput('city')}
            error={showErrorForField('city')}
            required
            disabled={shippingChecked}
          />
          {showStateDropdown ? (
            <StyledSelect
              id="state"
              clearIcon={false}
              label={getText('billing_address.region')}
              i18n={{
                clear: 'Clear',
                noResults: 'No results found',
                open: getText('state_dropdown.open_tooltip'),
                searchPlaceholder: 'Search Items',
                selected: 'Selected',
                showingResult: 'Showing %s result',
                showingResults: 'Showing %s results',
                unselected: 'Unselected'
              }}
              options={stateOptions}
              data-testid="state"
              value={[state || '']}
              visibleOptions={containerSize === MOBILE_CONTAINER ? 6 : 8}
              onChange={onChangeSelect('state')}
              error={showErrorForField('state')}
              required
              disabled={shippingChecked}
            />
          ) : null}
        </StyledInputGroup>
        <StyledInputGroup>
          <StyledTextBox
            id="zip"
            name="zip"
            label={getText('billing_address.zip')}
            value={zipCode || ''}
            onChange={onChangeInput('zipCode')}
            error={showErrorForField('zipCode')}
            required
            disabled={shippingChecked}
          />
          <StyledTextBox
            id="country"
            name="country"
            label={getText('billing_address.country')}
            value={countryCode || ''}
            readOnly
            disabled
          />
        </StyledInputGroup>
      </StyledFormFields>
    </VeneerThemeProvider>
  )
}
