import React, { FC } from 'react'
import { useGetText } from '../../hooks'
import UpdateIcon from '@veneer/core/dist/scripts/icons/icon_update'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'
import { PayPalLogo } from './payPalLogo'
import * as Styled from './styles'

type PayPalCheckStatusContentProps = {
  loading: boolean
  onClick: () => void
  showFinishReminder: boolean
}
export const PayPalCheckStatusContent: FC<PayPalCheckStatusContentProps> = ({
  loading,
  onClick,
  showFinishReminder
}) => {
  const getText = useGetText()

  return (
    <Styled.ConfirmationModal data-testid="paypal-check-status-content">
      <UpdateIcon size={48} />
      <PayPalLogo data-testid="pay-pal-icon" marginTop={8} />
      <Styled.ConfirmationModalMessage>
        {getText('pay_pal_step.check_status.body')}
      </Styled.ConfirmationModalMessage>
      {showFinishReminder && (
        <InlineNotification
          closeButton={false}
          title={getText('pay_pal_step.check_status.reminder')}
          type="informative"
        />
      )}
      <Styled.ConfirmationModalButton
        onClick={onClick}
        appearance="secondary"
        loading={loading}
      >
        {getText('pay_pal_step.check_status.check_status_button')}
      </Styled.ConfirmationModalButton>
    </Styled.ConfirmationModal>
  )
}
