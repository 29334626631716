import React from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useGetText } from '../../hooks'
import { StyledSpinner } from './styles'

export function Spinner() {
  const getText = useGetText()

  return (
    <StyledSpinner data-testid="spinner">
      <ProgressIndicator />
      <span>{getText('billing_form.loading')}</span>
    </StyledSpinner>
  )
}
