import styled, { css } from 'styled-components'
import TextBox from '@veneer/core/dist/scripts/text_box'
import Button from '@veneer/core/dist/scripts/button'
import InfoIcon from '@veneer/core/dist/scripts/icons/icon_info'
import primitives from '@veneer/primitives'
import { breakpointsHelper } from '../Helpers/breakpointsHelper'

export const StyledTitle = styled.h2`
  && {
    font-weight: 400;
    color: ${primitives.color.gray12};
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 40px;

    @media screen and (max-width: ${breakpointsHelper.extra_small_575px}) {
      font-size: 20px;
      line-height: 28px;
    }
  }
`

export const StyledTaxIdForm = styled.div``

export const StyledInfoIcon = styled(InfoIcon)`
  && {
    display: flex;
    margin: 8px 8px 8px 12px;
  }
`

export const StyledTextBox = styled(TextBox)`
  && {
    width: 100%;
    ${({ error, value }) => {
      if (!error && value) {
        return css`
          & > div:not(.focused) {
            /*
              We are not using the veneer token here because it will
              not work on the current veneer version used by vulcan
            */
            border-color: #00834b;
          }
        `
      }
    }}
  }
`

export const StyledTooltipText = styled.p`
  && {
    margin: 0;
    text-align: center;
  }
`

export const StyledErrorMessageContainer = styled.div`
  && {
    display: flex;
    justify-content: start;
    & > div {
      margin-top: 8px;
      margin-bottom: 0px;
    }
  }
`

export const StyledButtons = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 40px;

    @media screen and (max-width: ${breakpointsHelper.extra_small_575px}) {
      flex-direction: column-reverse;
      width: 100%;
      margin-top: 16px;
    }
  }
`

export const StyledCancelButton = styled(Button)`
  && {
    margin-right: 16px;

    @media screen and (max-width: ${breakpointsHelper.extra_small_575px}) {
      margin-top: 16px;
      margin-right: 0px;
    }
  }
`
