import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchShippingAddressAction } from '../actions'

export const useShippingAddressLoading = () =>
  useBillingFormState().shippingAddressLoading

export const useShippingAddress = () => {
  const { shippingAddress, shippingAddressLoading, shippingAddressError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      shippingAddress === undefined &&
      !shippingAddressLoading &&
      !shippingAddressError
    ) {
      ;(async () => {
        try {
          await dispatch(fetchShippingAddressAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [shippingAddress, shippingAddressLoading, shippingAddressError, dispatch])

  return shippingAddress
}
