import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchBillingAddressAction } from '../actions'

export const useBillingAddressLoading = () =>
  useBillingFormState().billingAddressLoading

export const useBillingAddressSaving = () =>
  useBillingFormState().billingAddressSaving

export const useBillingAddressError = () =>
  useBillingFormState().billingAddressError

export const useBillingAddress = () => {
  const { billingAddress, billingAddressLoading, billingAddressError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      billingAddress === undefined &&
      !billingAddressLoading &&
      !billingAddressError
    ) {
      ;(async () => {
        try {
          await dispatch(fetchBillingAddressAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [billingAddress, billingAddressLoading, billingAddressError, dispatch])

  return billingAddress
}
