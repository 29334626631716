import React, { useEffect, useState, useCallback } from 'react'
import { useTwoCheckout } from '../../hooks/useTwoCheckout'
import { Spinner } from '../Spinner'
import {
  useBillingFormState,
  useBillingInfo,
  useCountry,
  useOnSave,
  useOnCancel,
  useOnUpdate,
  useOn2CoStatusUpdate,
  useShippingAddress,
  usePhcEventStatusPoller,
  useGetText,
  useBillingAddress,
  useSettings,
  useTaxpayerDetails,
  useDispatch
} from '../../hooks'
import { DataType, Status, PaymentState } from '../../types'
import { TwoCheckoutStatus } from './twoCheckoutStatus'
import {
  fetchBillingAddressAction,
  fetchShippingAddressAction,
  fetchTaxpayerDetailsAction
} from '../../actions'
import { ErrorMessagePage } from '../ErrorMessagePage'
import Cookies from 'js-cookie'

const TWO_CHECKOUT_ORDER_NUMBER_COOKIE = '2checkoutOrderNumber'
const { loading, success, failed, canceled } = PaymentState

export const TwoCheckoutContent = () => {
  const { status: twoCoScriptStatus, twoCoInlineCart } = useTwoCheckout()
  const shippingAddress = useShippingAddress()
  const billingAddress = useBillingAddress()
  const taxpayerDetails = useTaxpayerDetails()
  const billingInfo = useBillingInfo()
  const {
    language,
    nativeApp,
    virtualKeyboard,
    shippingAddressError,
    billingAddressError,
    taxpayerDetailsError
  } = useBillingFormState()
  const country = useCountry()
  const onSave = useOnSave()
  const onCancel = useOnCancel()
  const onUpdate = useOnUpdate()
  const dispatch = useDispatch()
  const on2CoStatusUpdate = useOn2CoStatusUpdate()
  const getText = useGetText()
  const [reloadCart, setReloadCart] = useState(
    Boolean(window.location.search.match(/reload-cart/))
  )
  const [checkoutReady, setCheckoutReady] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState<PaymentState | undefined>(
    undefined
  )
  const [eventGuids, setEventGuids] = useState<
    | {
        paymentFinalizedEventGuid: unknown
        paymentCartClosedEventGuid: unknown
      }
    | undefined
  >(undefined)
  const phcEventStatusPoller = usePhcEventStatusPoller()
  const settingsState = useSettings()
  const templates = billingInfo?.twoCheckout?.templates

  let template = templates?.web
  if (nativeApp) {
    template = templates?.native
  } else if (virtualKeyboard) {
    template = templates?.webWithVirtualKeyboard
  }

  const enableVerifoneBillingAddress =
    settingsState?.enableVerifoneBillingAddress

  const enableVerifoneCombineStreets =
    settingsState?.enableVerifoneCombineStreets

  const checkPaymentStatus = useCallback(
    async (orderId?: string) => {
      setPaymentStatus(loading)
      try {
        const phcEventStatus = await phcEventStatusPoller({ orderId })
        if (['success', 'successNoPaymentChanged'].includes(phcEventStatus)) {
          setPaymentStatus(success)
        } else {
          setPaymentStatus(failed)
        }
      } catch {
        setPaymentStatus(failed)
      }
    },
    [phcEventStatusPoller]
  )

  useEffect(() => {
    if (paymentStatus !== undefined) {
      on2CoStatusUpdate(paymentStatus)

      if ([success, failed, canceled].includes(paymentStatus)) {
        Cookies.remove(TWO_CHECKOUT_ORDER_NUMBER_COOKIE)

        if (paymentStatus === success) {
          setTimeout(() => {
            onUpdate(DataType.billingAddress)
            onSave()
          }, 1000)
        }

        if (paymentStatus === canceled) {
          onCancel()
        }
      }
    }
  }, [paymentStatus, on2CoStatusUpdate, onUpdate, onSave, onCancel])

  useEffect(() => {
    if (reloadCart) {
      const orderNumber = Cookies.get(TWO_CHECKOUT_ORDER_NUMBER_COOKIE)
      if (orderNumber) {
        ;(async () => {
          await checkPaymentStatus(orderNumber)
        })()
      } else {
        setReloadCart(false)
      }
    }
  }, [checkPaymentStatus, reloadCart])

  useEffect(() => {
    if (
      !reloadCart &&
      !checkoutReady &&
      shippingAddress !== undefined &&
      billingInfo !== undefined &&
      billingAddress !== undefined &&
      taxpayerDetails !== undefined &&
      twoCoInlineCart !== undefined
    ) {
      let paymentFinalized = false

      const hasBillingAddress = Boolean(billingAddress.street1)
      const {
        merchantId,
        enrollmentProductId,
        email,
        externalCustomerReference,
        testMode
      } = billingInfo.twoCheckout || {}

      twoCoInlineCart.setup.setConfig('app', {
        merchant: merchantId
      })
      twoCoInlineCart.setup.setConfig('cart', {
        host: 'https://secure.2checkout.com',
        customization: template
      })
      twoCoInlineCart.register()
      twoCoInlineCart.products.add({ code: enrollmentProductId })
      twoCoInlineCart.billing.setEmail(email)
      twoCoInlineCart.billing.setCountry(country)

      if (
        shippingAddress ||
        (enableVerifoneBillingAddress && hasBillingAddress)
      ) {
        const { firstName, lastName, city, state, zipCode, street1, street2 } =
          enableVerifoneBillingAddress && hasBillingAddress
            ? billingAddress
            : shippingAddress
        const company =
          taxpayerDetails?.companyName ||
          billingAddress?.company ||
          shippingAddress?.company
        const street2WithSpace = ` ${street2}`
        const modifiedStreet2 = street2 ? street2WithSpace : ''
        const street = enableVerifoneCombineStreets
          ? `${street1}${modifiedStreet2}`
          : street1

        twoCoInlineCart.billing.setName(`${firstName} ${lastName}`)
        twoCoInlineCart.billing.setCity(city)
        twoCoInlineCart.billing.setState(state)
        twoCoInlineCart.billing.setZip(zipCode)
        twoCoInlineCart.billing.setAddress(street)
        if (!enableVerifoneCombineStreets)
          twoCoInlineCart.billing.setAddress2(street2)
        twoCoInlineCart.billing.setPhone(shippingAddress?.phoneNumber1)
        twoCoInlineCart.billing.setCompanyName(company)
        twoCoInlineCart.billing.setFiscalCode(taxpayerDetails?.taxId)
      }

      twoCoInlineCart.cart.setSource('ENROLL')
      twoCoInlineCart.cart.setLanguage(
        language === 'zh' && ['hk', 'tw'].includes(country.toLowerCase())
          ? 'zy'
          : language
      )
      twoCoInlineCart.cart.setCurrency(billingInfo.currency)
      twoCoInlineCart.cart.setExternalCustomerReference(
        externalCustomerReference
      )
      if (
        !twoCoInlineCart.cart.getAdditionalFields()?.['HP_customer_reference']
      ) {
        twoCoInlineCart.cart.setAdditionalFields([
          { code: 'HP_customer_reference', value: externalCustomerReference }
        ])
      }
      twoCoInlineCart.cart.setOrderExternalRef(billingInfo.orderId)

      if (billingInfo.orderId) {
        Cookies.set(TWO_CHECKOUT_ORDER_NUMBER_COOKIE, billingInfo.orderId)
      }

      const paymentFinalizedEventGuid = twoCoInlineCart.events.subscribe(
        'payment:finalized',
        () => {
          paymentFinalized = true
          setTimeout(() => {
            twoCoInlineCart.cart.setIframeState(false)
          }, 0)
        }
      )

      const paymentCartClosedEventGuid = twoCoInlineCart.events.subscribe(
        'cart:closed',
        async () => {
          if (paymentFinalized) {
            await checkPaymentStatus()
          } else {
            setPaymentStatus(canceled)
          }
        }
      )

      twoCoInlineCart.cart.setTest(Boolean(testMode))
      twoCoInlineCart.cart.setReset(true)
      twoCoInlineCart.cart.checkout()

      setCheckoutReady(true)
      setEventGuids({ paymentFinalizedEventGuid, paymentCartClosedEventGuid })
    }
  }, [
    reloadCart,
    checkoutReady,
    checkPaymentStatus,
    enableVerifoneBillingAddress,
    twoCoInlineCart,
    shippingAddress,
    billingAddress,
    taxpayerDetails,
    billingInfo,
    country,
    language,
    onCancel,
    template,
    enableVerifoneCombineStreets
  ])

  useEffect(() => {
    if (
      checkoutReady &&
      paymentStatus !== undefined &&
      eventGuids !== undefined &&
      twoCoInlineCart !== undefined
    ) {
      const { paymentFinalizedEventGuid, paymentCartClosedEventGuid } =
        eventGuids
      if (paymentFinalizedEventGuid) {
        twoCoInlineCart.events.unsubscribe(
          'payment:finalized',
          paymentFinalizedEventGuid
        )
      }
      if (paymentCartClosedEventGuid) {
        twoCoInlineCart.events.unsubscribe(
          'cart:closed',
          paymentCartClosedEventGuid
        )
      }
      setEventGuids(undefined)
    }
  }, [checkoutReady, paymentStatus, eventGuids, twoCoInlineCart])

  if (paymentStatus) {
    return <TwoCheckoutStatus status={paymentStatus} />
  }

  if (!checkoutReady && !reloadCart) {
    if (twoCoScriptStatus === Status.ERROR) {
      return <p>{getText('error_message.something_went_wrong')}</p>
    }

    if (shippingAddressError || billingAddressError || taxpayerDetailsError) {
      const onRetry = async () => {
        const promises = []

        if (shippingAddressError) {
          promises.push(dispatch(fetchShippingAddressAction()))
        }

        if (billingAddressError) {
          promises.push(dispatch(fetchBillingAddressAction()))
        }

        if (taxpayerDetailsError) {
          promises.push(dispatch(fetchTaxpayerDetailsAction()))
        }

        try {
          await Promise.all(promises)
        } catch {
          // do nothing
        }
      }

      return <ErrorMessagePage onRetry={onRetry} />
    }

    return <Spinner />
  }

  return null
}
