import React from 'react'
import { useGetText } from '../../hooks'
import { StyledBillingTitle } from './styles'

export const BillingTitle = () => {
  const getText = useGetText()

  return (
    <StyledBillingTitle>{getText('billing_form.title')}</StyledBillingTitle>
  )
}
