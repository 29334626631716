import primitives from '@veneer/primitives'

export const breakpointsHelper = {
  extra_small_575px: `${primitives.layout.breakpoint0}px`,
  small_min_576px: `${primitives.layout.breakpoint1}px`,
  small_max_767px: `${primitives.layout.breakpoint2}px`,
  medium_min_768px: `${primitives.layout.breakpoint3}px`,
  medium_max_991px: `${primitives.layout.breakpoint4}px`,
  large_min_992px: `${primitives.layout.breakpoint5}px`,
  large_max_1199px: `${primitives.layout.breakpoint6}px`,
  extra_large_min_1200px: `${primitives.layout.breakpoint7}px`
}
