export const getRequiredFields = (showStateDropdown: boolean) => {
  const requiredFields = [
    'firstName',
    'lastName',
    'street1',
    'street2',
    'city',
    'zipCode'
  ]
  if (showStateDropdown) {
    requiredFields.push('state')
  }

  return { requiredFields }
}
