import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchTaxpayerDetailsAction } from '../actions'
import { useSettings } from './useSettings'
import { isTwoCheckout } from '../lib/twoCheckoutHelper'
import { useBillingInfo } from './useBillingInfo'

export const useTaxpayerDetailsLoading = () => {
  const { taxpayerDetailsLoading } = useBillingFormState()
  return taxpayerDetailsLoading
}

export const useTaxpayerDetailsSaving = () => {
  const { taxpayerDetailsSaving } = useBillingFormState()
  return taxpayerDetailsSaving
}

export const useTaxpayerDetailsError = () => {
  const { taxpayerDetailsError } = useBillingFormState()
  return taxpayerDetailsError
}

export const useTaxpayerDetails = () => {
  const { enableBillingAccountType } = useSettings() || {}
  const { paymentMethods } = useBillingInfo() || {}
  const { taxpayerDetails, taxpayerDetailsLoading, taxpayerDetailsError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      (enableBillingAccountType || isTwoCheckout(paymentMethods)) &&
      taxpayerDetails === undefined &&
      !taxpayerDetailsLoading &&
      !taxpayerDetailsError
    ) {
      ;(async () => {
        try {
          await dispatch(fetchTaxpayerDetailsAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [
    enableBillingAccountType,
    paymentMethods,
    taxpayerDetails,
    taxpayerDetailsLoading,
    taxpayerDetailsError,
    dispatch
  ])

  return taxpayerDetails
}
