import React from 'react'
import { BillingTitle } from '../BillingTitle'
import { BillingFlow } from '../BillingFlow'
import { BackButton } from '../BackButton'

export const BillingFormContent = () => {
  return (
    <div>
      <BackButton />
      <BillingTitle />
      <BillingFlow />
    </div>
  )
}
