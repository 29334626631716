import React, { useEffect, useRef, useState } from 'react'
import {
  useBillingAddress,
  useBillingInfo,
  useDispatch,
  useLocale,
  useOnSave,
  usePaymentType,
  usePhcEventStatusPoller,
  useOnSaveButtonClick,
  useBillingFormState,
  useGetText
} from '../../../hooks'
import { fetchBillingInfoAction } from '../../../actions'
import { PaymentType } from '../../../types'
import { Spinner } from '../../Spinner'
import { StyledPgsContainer, StyledPgsForm } from './styles'
import Button from '@veneer/core/dist/scripts/button'
import InlineNotification from '@veneer/core/dist/scripts/inline_notification'

export const PgsForm = () => {
  const getText = useGetText()
  const paymentType = usePaymentType()
  const {
    firstName,
    lastName,
    street1,
    street2,
    city,
    state,
    zipCode,
    countryCode
  } = useBillingAddress() || {}
  const {
    ipAddress,
    merchantName,
    orderId,
    customerId,
    customField,
    pgsIframeUrl
  } = useBillingInfo() || {}
  const { enableAllPaymentLayout, virtualKeyboard } = useBillingFormState()
  const dispatch = useDispatch()
  const phcEventStatusPoller = usePhcEventStatusPoller()
  const analyticsEventRef = useRef<HTMLButtonElement>(null)
  const onSave = useOnSave()
  const onSaveButtonClick = useOnSaveButtonClick()
  const locale = useLocale()
  const [initForm, setInitForm] = useState(true)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [showError, setShowError] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const iFrameId = 'pgs-iframe'
  const iFrameUrl = pgsIframeUrl?.replace(/\/$/, '')

  useEffect(() => {
    const postMessageToIframe = (message: string, targetOrigin: string) => {
      const iFrameWindow = iFrameRef.current?.contentWindow
      iFrameWindow?.postMessage(
        message,
        targetOrigin.length > 0 ? targetOrigin : '*'
      )
    }
    const reloadIframe = async () => {
      try {
        await dispatch(fetchBillingInfoAction())
        setInitForm(true)
      } catch {
        // do nothing
      }
    }
    const iframeListener = async (event: MessageEvent) => {
      if (event.data.msg === 'handlePgsIframeSuccess') {
        setShowError(false)
        try {
          const phcEventStatus = await phcEventStatusPoller({
            pickupId: event.data.pickupId
          })
          analyticsEventRef.current?.click()
          if (['success', 'successNoPaymentChanged'].includes(phcEventStatus)) {
            postMessageToIframe('displayPgsFormSuccess', event.origin)
            onSave()
            return
          }
        } catch {
          // do nothing
        }
        if (enableAllPaymentLayout) {
          await reloadIframe()
          setShowError(true)
        } else {
          postMessageToIframe('displayPgsFormFailure', event.origin)
          setTimeout(reloadIframe, 5000)
        }
      }
    }
    window.addEventListener('message', iframeListener)
    return () => {
      window.removeEventListener('message', iframeListener)
    }
  }, [dispatch, enableAllPaymentLayout, onSave, phcEventStatusPoller])

  useEffect(() => {
    if (initForm && formRef.current) {
      formRef.current.submit()
      setInitForm(false)
    }
  }, [initForm, formRef])

  const renderForm = () => {
    if (initForm) {
      const zip = zipCode ? zipCode.toUpperCase() : zipCode
      const paymentInfo =
        paymentType === PaymentType.direct_debit
          ? {
              DirectDebit: {
                IsServiceRequired: true,
                IsVK: Boolean(virtualKeyboard)
              }
            }
          : { CC: { IsServiceRequired: true, IsVK: Boolean(virtualKeyboard) } }
      const phcRequest = JSON.stringify({
        ...paymentInfo,
        MerchantName: merchantName,
        CustomerInfo: {
          IPAddress: ipAddress,
          FirstName: firstName,
          LastName: lastName
        },
        BillAddress: {
          Address1: street1,
          Address2: street2,
          City: city,
          State: state,
          Zip: zip,
          Country: countryCode
        },
        CustomField: JSON.stringify({
          FirstName: firstName,
          LastName: lastName,
          Address1: street1,
          Address2: street2,
          City: city,
          State: state,
          Zip: zip,
          CountryCode: countryCode,
          submitNoDataTouched: true,
          ...(customField ? JSON.parse(customField) : {})
        }),
        OrderID: orderId,
        CustomerID: customerId,
        Culture: locale.replace('_', '-')
      })
      return (
        <form
          ref={formRef}
          method="post"
          id="postToIframe"
          action={iFrameUrl}
          target={iFrameId}
        >
          <input type="hidden" name="phcRequest" value={phcRequest} />
        </form>
      )
    }
    return null
  }

  return (
    <>
      {showError ? (
        <InlineNotification
          id="pgs-form-error"
          title={getText('error_message.card_error')}
          type="negative"
          closeButton={false}
        />
      ) : null}
      {iframeLoaded ? null : <Spinner />}
      <StyledPgsContainer show={iframeLoaded}>
        <StyledPgsForm
          id={iFrameId}
          title={iFrameId}
          key={iFrameId}
          ref={iFrameRef}
          name={iFrameId}
          onLoad={() => setIframeLoaded(true)}
        />
        {renderForm()}
      </StyledPgsContainer>
      <Button
        data-analyticsid="SaveButton"
        style={{ display: 'none' }}
        ref={analyticsEventRef}
        onClick={(event) => {
          event.persist()
          onSaveButtonClick(event)
        }}
      />
    </>
  )
}
