import { IBillingService } from '../../lib/billingService'
import { AxiosResponse } from 'axios'
import { MockFixtures } from './mockFixtures'
import {
  settingsFixture,
  billingInfoFixture,
  taxpayerDetailsFixture,
  shippingAddressFixture,
  billingAddressFixture
} from '../fixtures'
import {
  BillingAddress,
  BillingInfo,
  Settings,
  ShippingAddress,
  TaxpayerDetails
} from '../../types'

export class MockBillingService implements IBillingService {
  private mockFixtures?: MockFixtures
  private defaultDelay: number

  constructor(mockFixtures?: MockFixtures, defaultDelay = 2000) {
    this.mockFixtures = mockFixtures
    this.defaultDelay = defaultDelay
  }

  private mockFixtureOverride(name: string): object {
    return this.mockFixtures?.getFixture(name) || {}
  }

  private resolve<T>(
    result: AxiosResponse<T>,
    delay: number = this.defaultDelay
  ): Promise<AxiosResponse<T>> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(result), delay)
    })
  }

  getSettings() {
    return this.resolve<Settings>({
      data: {
        ...settingsFixture(),
        ...this.mockFixtureOverride('settings')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getBillingInfo() {
    return this.resolve<BillingInfo>({
      data: {
        ...billingInfoFixture(),
        ...this.mockFixtureOverride('billingInfo')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getTaxpayerDetails() {
    return this.resolve<TaxpayerDetails>({
      data: {
        ...taxpayerDetailsFixture(),
        ...this.mockFixtureOverride('taxpayerDetails')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  saveTaxpayerDetails(data: TaxpayerDetails) {
    return this.resolve<TaxpayerDetails>({
      data: {
        ...taxpayerDetailsFixture(data)
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getBillingAddress(): Promise<AxiosResponse> {
    return this.resolve({
      data: null,
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  saveBillingAddress(address: BillingAddress) {
    return this.resolve<BillingAddress>({
      data: billingAddressFixture(address),
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getShippingAddress() {
    return this.resolve<ShippingAddress>({
      data: {
        ...shippingAddressFixture(),
        ...this.mockFixtureOverride('shippingAddress')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getPhcEvent() {
    return this.resolve({
      data: {
        status: 'success',
        ...this.mockFixtureOverride('phcEventStatus')
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  createPhcEvent() {
    return this.resolve({
      data: {
        orderId: 'some-order-id'
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  getPayPalToken() {
    return this.resolve({
      data: {
        token: 'some-paypal-token'
      },
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }

  createPayPalAgreement() {
    return this.resolve({
      data: [
        {
          agreement_status: 'success'
        }
      ],
      status: 200,
      statusText: 'ok',
      headers: {},
      config: {}
    })
  }
}
