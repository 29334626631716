import React, { useEffect, useState } from 'react'
import {
  useBillingFormState,
  useBillingInfo,
  useBillingInfoError,
  useContainerSize,
  useDispatch,
  useSettings,
  useSettingsError
} from '../../hooks'
import { TwoCheckoutContent } from '../TwoCheckoutContent'
import { BillingFormContent } from './billingFormContent'
import { Spinner } from '../Spinner'
import { ErrorMessagePage } from '../ErrorMessagePage'
import { fetchBillingInfoAction, fetchSettingsAction } from '../../actions'
import { ThemeProvider } from 'styled-components'
import { StyledBillingForm } from './styles'
import { isTwoCheckout } from '../../lib/twoCheckoutHelper'
import { useTheme as useVeneerTheme } from '@veneer/theme'

export const BillingFormContainer = () => {
  const dispatch = useDispatch()
  const billingInfo = useBillingInfo()
  const settings = useSettings()
  const settingsError = useSettingsError()
  const billingInfoError = useBillingInfoError()
  const [ready, setReady] = useState(false)
  const paymentMethods = billingInfo?.paymentMethods
  const containerSize = useContainerSize()
  const { trackClickEvent } = useBillingFormState()
  const { color } = useVeneerTheme()

  useEffect(() => {
    if (!ready) {
      if (billingInfo !== undefined && settings !== undefined) {
        setReady(true)
      }
    }
  }, [ready, billingInfo, settings])

  let result: JSX.Element = <BillingFormContent />

  const onRetry = async () => {
    const promises: Promise<unknown>[] = []
    const errors = {
      billingInfo: {
        error: billingInfoError,
        fetchAction: fetchBillingInfoAction
      },
      settings: {
        error: settingsError,
        fetchAction: fetchSettingsAction
      }
    }

    Object.values(errors).forEach((error) => {
      if (error.error) {
        promises.push(dispatch(error.fetchAction()))
      }
    })

    try {
      await Promise.all(promises)
    } catch {
      // do nothing
    }
  }

  if (!ready) {
    if (billingInfoError || settingsError) {
      result = <ErrorMessagePage onRetry={onRetry} />
    } else {
      result = <Spinner />
    }
  }

  if (isTwoCheckout(paymentMethods)) {
    result = <TwoCheckoutContent />
  }

  return (
    <ThemeProvider
      theme={{
        containerSize,
        updatedLayout: settings?.enableBillingUpdatedLayout,
        color
      }}
    >
      <StyledBillingForm
        onClick={(event) => {
          try {
            const linkId = (event.target as Element).getAttribute(
              'data-analyticsid'
            )
            if (linkId) {
              trackClickEvent?.call(null, linkId, 'payment-information')
            }
          } catch {
            // do nothing
          }
        }}
      >
        {result}
      </StyledBillingForm>
    </ThemeProvider>
  )
}
