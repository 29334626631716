import React from 'react'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { StyledSpinner } from './styles'

export function SpinnerWithoutText() {
  return (
    <StyledSpinner data-testid="spinner-without-text">
      <ProgressIndicator />
    </StyledSpinner>
  )
}
