import styled, { css } from 'styled-components'
import primitives from '@veneer/primitives'
import Button from '@veneer/core/dist/scripts/button'
import { breakpointsHelper } from '../Helpers/breakpointsHelper'

export const PayPalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 16px;
  margin-top: 32px;
  width: 100%;

  > * {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  #pay-pal-button {
    align-self: flex-end;
    margin-top: 8px;
  }
`

export const PayPalErrorMessage = styled.div`
  color: ${primitives.color.red7};
`

export const PayPalInlineErrorMessage = styled.p`
  b {
    font-family: 'Forma DJR UI', Arial, sans-serif;
    font-weight: bold;
  }
`

export const PayPalInfo = styled.div`
  line-height: 35px;
  width: 100%;

  > strong {
    vertical-align: top;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    line-height: 20px;
  }
`

export const PayPalWhatIs = styled.a`
  && {
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary.base.default};

    &:hover {
      color: ${({ theme }) => theme.color.primary.base.default};
    }
  }
`

export const PaypalContainer = styled.div`
  width: 100%;
`

export const PayPalButton = styled.button`
  width: 100%;
  border-radius: 4px;
  background: #ffc439;
  border: none;
  padding: 10px 0;
  pointer-events: none;
`

export const PayPalButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;

  a {
    width: 100%;
  }
`

export const PayPalTagline = styled.span`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  line-height: 20px;
  font-size: 13px;
`

export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 12px;
`
export const ConfirmationModalButton = styled(Button)`
  && {
    align-self: flex-end;
    margin-top: 24px;

    @media (min-width: ${breakpointsHelper.small_min_576px}) {
      align-self: stretch;
    }
  }
`
export const ConfirmationModalMessage = styled.p`
  margin: 0;
`

export const Email = styled.p`
  font-weight: bold;
  margin: 0;
`
export const PayPalButtonContainer = styled.div<{
  $enableAllPaymentLayout: boolean
}>`
  && {
    ${(props) => {
      if (!props.$enableAllPaymentLayout) {
        return css`
          text-align: 'right';
          display: flex;
          justify-content: flex-end;
          > div {
            width: 280px;

            @media (max-width: ${breakpointsHelper.small_min_576px}) {
              width: 100%;
            }
          }
        `
      }
    }}
  }
`
