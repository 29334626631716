import { BillingInfo, BillingInfoTwoCheckout, PaymentType } from '../../types'

export const billingInfoFixture = ({
  currency = 'USD',
  customerId = 'some-customer-id',
  orderId = 'some-order-id',
  ipAddress = '127.0.0.1',
  customField = undefined,
  merchantName = '70HJG91RV89SC15B811FV59YW5INKUS',
  paymentMethods = [
    PaymentType.credit_card,
    PaymentType.direct_debit,
    PaymentType.pay_pal
  ],
  // paymentMethods = [PaymentType.two_checkout],
  paymentType = undefined,
  newPaymentSwitcherStrings = true,
  pgsIframeUrl = 'https://vphcitg.pgs.ext.hp.com/PGSAllpayment_V2/Payment/Pay',
  twoCheckout = undefined,
  // twoCheckout = {
  //   email: 'test@hp.com',
  //   enrollmentProductId: 'f9ad6d87-295f-41d4-bf90-e6b0fa1ff670',
  //   externalCustomerReference: 'some reference',
  //   merchantId: 'HPINCDYZ',
  //   templates: {
  //     native: 'HP-inline-v3',
  //     web: 'HP-inline',
  //     webWithVirtualKeyboard: 'HP-inline-v4'
  //   },
  //   testMode: true
  // },
  creditCard = {
    iframeUrl:
      'https://vphcitg.pgs.ext.hp.com/InstantInkLite/?card_type_key=&expiration_month=&expiration_year=&locale=en_US&payment_type=credit_card&redacted_credit_card_number=&stack=development'
  },
  directDebit = {
    iframeUrl:
      'https://vphcitg.pgs.ext.hp.com/InstantInkLite/?bank_name=&e_mandate_acceptance_date=&locale=en_US&mandate_identifier=&payment_type=direct_debit&redacted_iban=&stack=development'
  },
  payPal = {
    email: 'email@paypal.com',
    env: 'sandbox',
    locale: 'en_US',
    pay_pal_url: 'https://www.sandbox.paypal.com/'
  }
} = {}): BillingInfo => ({
  currency,
  customerId,
  orderId,
  ipAddress,
  customField,
  merchantName,
  paymentMethods,
  paymentType,
  newPaymentSwitcherStrings,
  pgsIframeUrl,
  twoCheckout,
  creditCard,
  directDebit,
  payPal
})

export const twoCheckoutFixture = ({
  email = 'test@hp.com',
  enrollmentProductId = 'f9ad6d87-295f-41d4-bf90-e6b0fa1ff670',
  externalCustomerReference = 'some reference',
  merchantId = 'HPINCDYZ',
  templates = {
    native: 'HP-inline-v3',
    web: 'HP-inline',
    webWithVirtualKeyboard: 'HP-inline-v4'
  },
  testMode = true
} = {}): BillingInfoTwoCheckout => ({
  email,
  enrollmentProductId,
  externalCustomerReference,
  merchantId,
  templates,
  testMode
})
