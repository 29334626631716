import React, { FC } from 'react'
import ReactDOM from 'react-dom'
import { PayPalButton } from '../types'
import { useScript } from './useScript'
import { paypalScriptUrl } from '../lib/url'
import { PayPalButtonProps } from 'src/types/payPalButton'

interface PayPalInterface {
  isEligible: () => boolean
  Button: {
    driver: (type: string, options: unknown) => FC<PayPalButtonProps>
  }
}

export const usePayPalButton = (): PayPalButton => {
  const { status, windowObject } = useScript('paypal', paypalScriptUrl)
  const payPal = windowObject ? (windowObject as PayPalInterface) : undefined
  const component = payPal?.isEligible()
    ? payPal.Button.driver('react', { React, ReactDOM })
    : undefined

  return { status, component }
}
