import { AxiosResponse } from 'axios'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { Stack, AccountMgtSvcClient, Account } from '@jarvis/web-stratus-client'

export interface IStratusService {
  getUserAccount(): Promise<AxiosResponse<Account>>
}

export class StratusService implements IStratusService {
  stack: Stack
  authProvider: JarvisAuthProvider

  constructor(authProvider: JarvisAuthProvider, stack: Stack) {
    this.authProvider = authProvider
    this.stack = stack
  }

  getUserAccount(): Promise<AxiosResponse<Account>> {
    const client = new AccountMgtSvcClient(this.stack, this.authProvider)
    return client.getAccount()
  }
}
