import React from 'react'
import {
  useBillingFormState,
  useBillingInfo,
  useGetText,
  usePaymentType
} from '../../hooks'
import { PaymentType } from '../../types'
import { PgsForm } from '../PgsForm'
import { StyledStep } from '../Shared/styles'
import { OrBar, OrSection, PaymentButtons, PgsFormSection } from './styles'
import { PayPalButton } from '../PayPal/payPalButton'
import { GPayButton } from '../GPay'

export const AllPaymentLayout = () => {
  const { enableGPay, nativeApp, enablePaypalInNativeApp } =
    useBillingFormState()
  const { paymentMethods } = useBillingInfo() || { paymentMethods: [] }
  const paymentType = usePaymentType()
  const getText = useGetText()
  const showPayPal = () =>
    Boolean(paymentMethods?.includes(PaymentType.pay_pal)) &&
    (!nativeApp || enablePaypalInNativeApp)
  const paymentButtons = []
  const shouldShowGpay = !nativeApp && enableGPay

  if (paymentType === PaymentType.credit_card) {
    if (shouldShowGpay) {
      paymentButtons.push(
        <GPayButton key="gpay-button" data-testid="gpay-button" />
      )
    }

    if (showPayPal()) {
      paymentButtons.push(
        <PayPalButton key="paypal-button" data-testid="paypal-button" />
      )
    }
  }
  const hasMorePaymentMethods = paymentButtons.length > 0

  return (
    <StyledStep>
      {hasMorePaymentMethods && (
        <>
          <PaymentButtons>{paymentButtons}</PaymentButtons>
          <OrSection>
            <OrBar />
            <span>{getText('all_payment_layout.or')}</span>
            <OrBar />
          </OrSection>
        </>
      )}
      <PgsFormSection hasMorePaymentMethods={hasMorePaymentMethods}>
        <PgsForm />
      </PgsFormSection>
    </StyledStep>
  )
}
