import { useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchSettingsAction } from '../actions'

export const useSettingsLoading = () => useBillingFormState().settingsLoading

export const useSettingsError = () => useBillingFormState().settingsError

const fetching: Record<number, boolean> = {}

export const useSettings = () => {
  const { id, settings, settingsLoading, settingsError } = useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      settings === undefined &&
      !settingsLoading &&
      !settingsError &&
      !fetching[id]
    ) {
      ;(async () => {
        try {
          fetching[id] = true
          await dispatch(fetchSettingsAction())
        } catch {
          // do nothing
        } finally {
          fetching[id] = false
        }
      })()
    }
  }, [id, settings, settingsLoading, settingsError, dispatch])

  return settings
}
