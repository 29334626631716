import { useEffect, useRef, useState } from 'react'
import { PaymentState, Status } from '../types'
import { loadScript } from '../lib/scriptHelper'
import { waitFor } from '../lib/waitFor'
import { useOn2CoStatusUpdate } from './useOn2CoStatusUpdate'

export const useScript = (
  objectName: string,
  scriptSrc: string,
  preload?: () => void,
  postload?: () => void
) => {
  const [status, setStatus] = useState(Status.INIT)
  const [windowObject, setWindowObject] = useState<unknown>(undefined)
  const unmountedRef = useRef(false)
  const on2CoStatusUpdate = useOn2CoStatusUpdate()

  useEffect(() => {
    return () => {
      unmountedRef.current = true
    }
  }, [])

  useEffect(() => {
    if (status === Status.INIT) {
      preload?.call(null)
      loadScript(`billing-${objectName}-script`, scriptSrc)
      setStatus(Status.LOADING)

      waitFor(
        async () => {
          if (typeof window[objectName] === 'object') {
            return window[objectName]
          }
          throw new Error()
        },
        100,
        60000
      ).then(
        (result) => {
          if (!unmountedRef.current) {
            setWindowObject(result)
            setStatus(Status.LOADED)
            if (objectName === 'TwoCoInlineCart') {
              on2CoStatusUpdate(PaymentState.pending)
            }
          }
          postload?.call(null)
        },
        () => {
          if (!unmountedRef.current) {
            setStatus(Status.ERROR)
          }
          postload?.call(null)
        }
      )
    }
  }, [
    status,
    unmountedRef,
    objectName,
    scriptSrc,
    on2CoStatusUpdate,
    preload,
    postload
  ])

  return { status, windowObject }
}
