import React, { FC } from 'react'

export const GPayIcon: FC<{ ['data-testid']: string }> = (props) => (
  <svg
    data-testid={props['data-testid']}
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="24"
    viewBox="0 0 44 24"
    fill="none"
  >
    <g id="Group 1104216172">
      <path
        id="Base_1_"
        d="M31.7247 0.5H11.4945C5.17251 0.5 0 5.675 0 12C0 18.325 5.17251 23.5 11.4945 23.5H31.7247C38.0467 23.5 43.2192 18.325 43.2192 12C43.2192 5.675 38.0467 0.5 31.7247 0.5Z"
        fill="white"
      />
      <path
        id="Outline"
        d="M31.7247 1.4315C33.1443 1.4315 34.5236 1.71325 35.8225 2.26525C37.0812 2.8 38.2076 3.56475 39.1846 4.5365C40.1559 5.50825 40.9203 6.641 41.4548 7.90025C42.0065 9.19975 42.2881 10.5798 42.2881 12C42.2881 13.4202 42.0065 14.8002 41.4548 16.0997C40.9203 17.359 40.1559 18.486 39.1846 19.4635C38.2134 20.4352 37.0812 21.2 35.8225 21.7347C34.5236 22.2867 33.1443 22.5685 31.7247 22.5685H11.4945C10.0749 22.5685 8.69556 22.2867 7.39669 21.7347C6.13805 21.2 5.01159 20.4352 4.03456 19.4635C3.06328 18.4917 2.29889 17.359 1.7644 16.0997C1.21267 14.8002 0.931052 13.4202 0.931052 12C0.931052 10.5798 1.21267 9.19975 1.7644 7.90025C2.29889 6.641 3.06328 5.514 4.03456 4.5365C5.00584 3.56475 6.13805 2.8 7.39669 2.26525C8.69556 1.71325 10.0749 1.4315 11.4945 1.4315H31.7247ZM31.7247 0.5H11.4945C5.17251 0.5 0 5.675 0 12C0 18.325 5.17251 23.5 11.4945 23.5H31.7247C38.0467 23.5 43.2192 18.325 43.2192 12C43.2192 5.675 38.0467 0.5 31.7247 0.5Z"
        fill="#3C4043"
      />
      <g id="G_Pay_Lockup_1_">
        <g id="Pay_Typeface_3_">
          <path
            id="Letter_p_3_"
            d="M20.6093 12.8168V16.2956H19.5059V7.70508H22.4312C23.1726 7.70508 23.8048 7.95233 24.322 8.44683C24.8508 8.94133 25.1152 9.54508 25.1152 10.2581C25.1152 10.9883 24.8508 11.5921 24.322 12.0808C23.8105 12.5696 23.1783 12.8111 22.4312 12.8111H20.6093V12.8168ZM20.6093 8.76308V11.7588H22.4542C22.891 11.7588 23.2588 11.6093 23.5462 11.3161C23.8393 11.0228 23.9887 10.6663 23.9887 10.2638C23.9887 9.86708 23.8393 9.51633 23.5462 9.22308C23.2588 8.91833 22.8967 8.76883 22.4542 8.76883H20.6093V8.76308Z"
            fill="#3C4043"
          />
          <path
            id="Letter_a_3_"
            d="M28.0003 10.2236C28.8164 10.2236 29.4601 10.4421 29.9313 10.8791C30.4026 11.3161 30.6383 11.9141 30.6383 12.6731V16.2956H29.5865V15.4791H29.5405C29.0865 16.1519 28.4773 16.4854 27.7187 16.4854C27.0692 16.4854 26.529 16.2956 26.0922 15.9104C25.6554 15.5251 25.437 15.0479 25.437 14.4729C25.437 13.8634 25.6669 13.3804 26.1267 13.0239C26.5865 12.6616 27.2014 12.4834 27.9658 12.4834C28.621 12.4834 29.1612 12.6041 29.5808 12.8456V12.5926C29.5808 12.2074 29.4313 11.8854 29.1267 11.6151C28.8221 11.3449 28.4658 11.2126 28.0578 11.2126C27.4428 11.2126 26.9543 11.4714 26.598 11.9946L25.6267 11.3851C26.1612 10.6089 26.9543 10.2236 28.0003 10.2236ZM26.575 14.4901C26.575 14.7776 26.6957 15.0191 26.9428 15.2089C27.1842 15.3986 27.4715 15.4964 27.7991 15.4964C28.2647 15.4964 28.6785 15.3239 29.0405 14.9789C29.4026 14.6339 29.5865 14.2314 29.5865 13.7656C29.2417 13.4954 28.7647 13.3574 28.1497 13.3574C27.7014 13.3574 27.3279 13.4666 27.029 13.6794C26.7244 13.9036 26.575 14.1739 26.575 14.4901Z"
            fill="#3C4043"
          />
          <path
            id="Letter_y_3_"
            d="M36.6383 10.4141L32.9601 18.8781H31.8221L33.19 15.9168L30.7646 10.4141H31.9658L33.713 14.6346H33.736L35.4371 10.4141H36.6383Z"
            fill="#3C4043"
          />
        </g>
        <g id="G_Mark_1_">
          <path
            id="Blue_500"
            d="M16.2198 12.1147C16.2198 11.7547 16.1876 11.4103 16.1278 11.0791H11.5024V12.9766L14.1663 12.9772C14.0582 13.6085 13.7105 14.1467 13.1778 14.5055V15.7366H14.7634C15.6893 14.8793 16.2198 13.612 16.2198 12.1147Z"
            fill="#4285F4"
          />
          <path
            id="Green_500_1_"
            d="M13.1783 14.5045C12.737 14.8023 12.1686 14.9766 11.5036 14.9766C10.2191 14.9766 9.12942 14.1106 8.73918 12.9434H7.10352V14.213C7.91388 15.8218 9.57942 16.9258 11.5036 16.9258C12.8335 16.9258 13.9508 16.4882 14.764 15.735L13.1783 14.5045Z"
            fill="#34A853"
          />
          <path
            id="Yellow_500_1_"
            d="M8.58529 12.0021C8.58529 11.6743 8.63989 11.3575 8.73931 11.0596V9.79004H7.10365C6.76859 10.4553 6.58008 11.2063 6.58008 12.0021C6.58008 12.7979 6.76916 13.5488 7.10365 14.2141L8.73931 12.9445C8.63989 12.6466 8.58529 12.3298 8.58529 12.0021Z"
            fill="#FABB05"
          />
          <path
            id="Red_500"
            d="M11.5036 9.0264C12.2295 9.0264 12.8795 9.27652 13.3927 9.76527L14.7979 8.36055C13.9444 7.56532 12.8318 7.07715 11.5036 7.07715C9.58 7.07715 7.91388 8.18115 7.10352 9.79L8.73918 11.0596C9.12942 9.89235 10.2191 9.0264 11.5036 9.0264Z"
            fill="#E94235"
          />
        </g>
      </g>
    </g>
  </svg>
)
