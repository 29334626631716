import React, { useRef, useEffect, useState } from 'react'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import InfoIcon from '@veneer/core/dist/scripts/icons/icon_info'
import Tooltip from '@veneer/core/dist/scripts/tooltip'
import {
  useSettings,
  useGetText,
  useTaxpayerDetails,
  useDispatch,
  useErrorFields,
  useUserAccount,
  useBillingFormState
} from '../../hooks'
import {
  updateTaxpayerDetailAccountTypeAction,
  updateTaxpayerDetailAction,
  validateTaxpayerDetailsAction,
  clearItalyTaxpayerDetailAction,
  setItalyTaxFieldTypeAction
} from '../../actions'
import {
  StyledAccountTypeSection,
  StyledTaxpayerFields,
  StyledTextBox,
  StyledSubtitle,
  StyledAccountTypeSelectorButtons,
  StyledVeneerRadioContent,
  StyledTooltipText,
  StyledTaxIdType,
  StyledTaxField,
  StyledRightInfoIcon
} from './styles'
import { AccountType, TaxpayerDetails } from '../../types'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'

export const AccountTypeSelector = () => {
  const dispatch = useDispatch()
  const getText = useGetText()
  const {
    enableBillingAccountType,
    billingAccountTypeFields,
    enableVatExamples
  } = useSettings() || {}
  const { italyTaxFieldType } = useBillingFormState()
  const taxpayerDetails = useTaxpayerDetails()
  const errorFields = useErrorFields()
  const accountTypesRef = useRef<{
    business: TaxpayerDetails
    consumer: TaxpayerDetails
  }>({
    business: { accountType: AccountType.business },
    consumer: { accountType: AccountType.consumer }
  })
  const userAccount = useUserAccount()
  const isSmb = userAccount?.type === 'BusinessTransactionalSMB'
  const [originalAccountType, setOriginalAccountType] = useState<
    AccountType | undefined
  >(undefined)
  const fieldConfig = {
    companyName: {
      showExampleToolTip: false
    },
    pecEmailOrRecipientCode: {
      showExampleToolTip: false
    },
    nonProfitTaxId: {
      showExampleToolTip: enableVatExamples
    },
    taxId: {
      showExampleToolTip: enableVatExamples,
      differentiateConsumerVsBusiness: true
    },
    taxIdOrNonProfitTaxId: {
      showExampleToolTip: enableVatExamples,
      differentiateConsumerVsBusiness: true
    }
  }

  useEffect(() => {
    if (originalAccountType === undefined && taxpayerDetails && userAccount) {
      const defaultAccountType = isSmb
        ? AccountType.business
        : AccountType.consumer
      const accountType = taxpayerDetails.accountType || defaultAccountType
      setOriginalAccountType(accountType)
      if (taxpayerDetails.accountType) {
        accountTypesRef.current[accountType] = {
          ...taxpayerDetails
        }
      } else {
        ;(async () => {
          await dispatch(
            updateTaxpayerDetailAccountTypeAction(
              accountTypesRef.current[accountType]
            )
          )
        })()
      }
    }
  }, [originalAccountType, taxpayerDetails, userAccount, isSmb, dispatch])

  useEffect(() => {
    if (
      italyTaxFieldType === undefined &&
      taxpayerDetails &&
      taxpayerDetails.accountType &&
      billingAccountTypeFields
    ) {
      const { required = [], optional = [] } =
        billingAccountTypeFields[taxpayerDetails.accountType] || {}
      const allFields = [...required, ...optional]
      if (allFields.includes('taxIdOrNonProfitTaxId')) {
        const { taxId, nonProfitTaxId } = taxpayerDetails
        if (Boolean(taxId) && Boolean(nonProfitTaxId)) {
          ;(async () => {
            await dispatch(clearItalyTaxpayerDetailAction())
            await dispatch(setItalyTaxFieldTypeAction('nonProfitTaxId'))
          })()
        } else {
          ;(async () => {
            await dispatch(
              setItalyTaxFieldTypeAction(taxId ? 'taxId' : 'nonProfitTaxId')
            )
          })()
        }
      }
    }
  }, [italyTaxFieldType, taxpayerDetails, billingAccountTypeFields, dispatch])

  if (!enableBillingAccountType || !taxpayerDetails || !userAccount) {
    return null
  }

  const { lockedFields, accountType } = taxpayerDetails
  const { required = [], optional = [] } =
    (accountType && billingAccountTypeFields?.[accountType]) || {}
  const allFields = [...required, ...optional]
  const orderedFields: string[] = Object.keys(fieldConfig)
    .map((orderedFieldName) =>
      allFields.find((fieldName) => orderedFieldName === fieldName)
    )
    .filter((fieldName) => fieldName !== undefined) as string[]
  const hasFields = allFields.length > 0
  const isRequired = (field: string) => required.includes(field)
  const showErrorForField = (field: string) => errorFields.has(field)

  const onChangeInput = (field: string) => async (value: string) => {
    if (accountType) {
      await dispatch(updateTaxpayerDetailAction({ field, value }))
      accountTypesRef.current[accountType][field] = value
    }
  }

  const onChangeAccountType = async (value: string) => {
    accountTypesRef.current[value === 'consumer' ? 'business' : 'consumer'] = {
      ...taxpayerDetails
    }
    await dispatch(
      updateTaxpayerDetailAccountTypeAction(accountTypesRef.current[value])
    )
    await dispatch(validateTaxpayerDetailsAction({ ignoreEmptyFields: true }))
  }

  const taxDetailFields = hasFields && (
    <StyledTaxpayerFields data-testid="taxpayer-fields">
      {orderedFields.map((fieldName) => {
        let showTaxIdType = false

        if (fieldName === 'taxIdOrNonProfitTaxId') {
          if (!italyTaxFieldType) return null

          showTaxIdType = true
          fieldName = italyTaxFieldType
        }

        const toKebabCase = (str: string) =>
          str.replace(/[A-Z]/g, (m) => '-' + m.toLowerCase())

        const toSnakeCase = (str: string) =>
          str.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase())

        const taxIdExample =
          (fieldConfig[fieldName].differentiateConsumerVsBusiness
            ? accountType + '_'
            : '') + toSnakeCase(fieldName)

        return (
          <StyledTaxField key={`fieldName-${fieldName}`}>
            <VeneerThemeProvider shape="round">
              <StyledTextBox
                id={toKebabCase(fieldName)}
                data-testid={toKebabCase(fieldName)}
                name={toKebabCase(fieldName)}
                label={getText(
                  `billing_account_type.${toSnakeCase(fieldName)}${
                    optional.includes(fieldName) ? '_optional' : ''
                  }`
                )}
                value={taxpayerDetails[fieldName] || ''}
                onChange={onChangeInput(fieldName)}
                error={showErrorForField(fieldName)}
                required={isRequired(
                  showTaxIdType ? 'taxIdOrNonProfitTaxId' : fieldName
                )}
                disabled={lockedFields?.includes(fieldName)}
                trailingIcon={
                  fieldConfig[fieldName].showExampleToolTip ? (
                    <Tooltip
                      content={
                        <StyledTooltipText
                          dangerouslySetInnerHTML={{
                            __html: getText(
                              `billing_account_type.${taxIdExample}_example_html`
                            )
                          }}
                        />
                      }
                      placement="bottom"
                      arrow
                    >
                      <StyledRightInfoIcon>
                        <InfoIcon
                          data-testid={`${toKebabCase(fieldName)}-tooltip`}
                        />
                      </StyledRightInfoIcon>
                    </Tooltip>
                  ) : null
                }
                $no-margin
              />
              {showTaxIdType && (
                <StyledTaxIdType
                  onClick={async () => {
                    await dispatch(clearItalyTaxpayerDetailAction())
                    await dispatch(
                      setItalyTaxFieldTypeAction(
                        fieldName === 'taxId' ? 'nonProfitTaxId' : 'taxId'
                      )
                    )
                  }}
                >
                  {getText('billing_account_type.switch')}{' '}
                  {getText(
                    `billing_account_type.${toSnakeCase(
                      fieldName === 'taxId' ? 'nonProfitTaxId' : 'taxId'
                    )}`
                  )}
                </StyledTaxIdType>
              )}
            </VeneerThemeProvider>
          </StyledTaxField>
        )
      })}
    </StyledTaxpayerFields>
  )

  return (
    <StyledAccountTypeSection>
      {isSmb && originalAccountType === 'business' ? null : (
        <>
          <StyledSubtitle>
            {getText('billing_account_type.title')}
          </StyledSubtitle>
          <StyledAccountTypeSelectorButtons>
            {['consumer', 'business'].map((account) => (
              <RadioButton
                key={account}
                label={
                  <StyledVeneerRadioContent>
                    {getText(`billing_account_type.${account}`)}
                  </StyledVeneerRadioContent>
                }
                checked={accountType === account}
                disabled={lockedFields?.includes('accountType')}
                onChange={() => onChangeAccountType(account)}
                data-testid={`${account}-radio-button`}
                data-analyticsid={`${account}-radio-button`}
              />
            ))}
          </StyledAccountTypeSelectorButtons>{' '}
        </>
      )}
      {taxDetailFields}
    </StyledAccountTypeSection>
  )
}
