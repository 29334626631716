import styled, { css } from 'styled-components'
import ChevronLeft from '@veneer/core/dist/scripts/icons/icon_chevron_left'

export const StyledBackButton = styled.div`
  display: flex;
  align-self: flex-start;
  padding: 0;
  margin-bottom: 16px;

  > button {
    padding: 12px 16px 12px 0;
  }

  ${(props) => {
    if (props.theme.updatedLayout) {
      return null
    }

    const tabPortMobile = css`
      top: 0;
      left: 0;
      transform: scale(0.8);
      transform-origin: top left;

      > button {
        padding: 0;
      }
    `
    const responsive = {
      'mobile-container': tabPortMobile,
      'tablet-portrait-container': tabPortMobile
    }
    return responsive[props.theme.containerSize]
  }}
`

export const StyledButtonContent = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 8px;
  }
`

export const ChevronLeftIcon = styled(ChevronLeft)`
  && {
    color: ${({ theme }) => theme.color.primary.base.default};
  }
`
