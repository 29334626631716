import styled, { css } from 'styled-components'

interface PgsFormProps {
  iFrameHeight: string
}

export const StyledPgsForm = styled.iframe<PgsFormProps>`
  width: 100%;
  height: ${({ iFrameHeight }) => iFrameHeight};
  border: none;
`

interface PgsContainerProps {
  show: boolean
}

export const StyledPgsContainer = styled.div<PgsContainerProps>`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;

  ${(props) =>
    props.show
      ? css``
      : css`
          display: none;
        `}
`
