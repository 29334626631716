import React, { useMemo } from 'react'
import { usePaymentType, useSettings } from '../../hooks'
import { PaymentType } from '../../types'
import { PgsForm as V1PgsForm } from './v1/pgsForm'
import { PgsForm as V2PgsForm } from './v2/pgsForm'

const { credit_card, direct_debit } = PaymentType

export const PgsForm = () => {
  const { enablePgsHostedFormV2, enableDirectDebitAllPaymentFormV2 } =
    useSettings() || {}
  const paymentType = usePaymentType()
  const useV2Form = useMemo(
    () =>
      (paymentType === credit_card && enablePgsHostedFormV2) ||
      (paymentType === direct_debit && enableDirectDebitAllPaymentFormV2),
    [paymentType, enablePgsHostedFormV2, enableDirectDebitAllPaymentFormV2]
  )

  return useV2Form ? <V2PgsForm /> : <V1PgsForm />
}
