import React from 'react'
import { useBillingFormState, useGetText, usePaymentType } from '../../hooks'
import { PaymentType } from '../../types'
import { PayPalContent } from '../PayPal'
import { PgsForm } from '../PgsForm'
import { AllPaymentLayout } from '../AllPaymentLayout'
import {
  StyledHeader,
  StyledStep,
  StyledStepNumber,
  StyledStepTitle
} from '../Shared/styles'

export const StepTwo = () => {
  const paymentType = usePaymentType()
  const { enableAllPaymentLayout } = useBillingFormState()
  const getText = useGetText()

  let subtitle = getText(`${paymentType}_form.sub_title`)
  let content: JSX.Element = <PgsForm />

  if (paymentType === PaymentType.pay_pal) {
    subtitle = getText('billing_type_selector.pay_pal.pay_pal_sub_title')
    content = <PayPalContent />
  }

  if (enableAllPaymentLayout) {
    subtitle = getText('all_payment_layout.sub_title')
    content = <AllPaymentLayout />
  }

  return (
    <StyledStep>
      <StyledHeader>
        <StyledStepNumber>
          {getText('billing_form.step_title.2')}:{' '}
        </StyledStepNumber>
        <StyledStepTitle>{subtitle}</StyledStepTitle>
      </StyledHeader>
      {content}
    </StyledStep>
  )
}
