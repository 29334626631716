import React, { FC } from 'react'
import { useGetText, useOnCancel } from '../../hooks'
import Button from '@veneer/core/dist/scripts/button'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import IconCheckmark from '@veneer/core/dist/scripts/icons/icon_checkmark'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import { StyledContent, StyledTitle, StyledIcon, StyledMessage } from './styles'
import { PaymentState } from '../../types'

type TwoCheckoutStatusProps = {
  status?: PaymentState
}

export const TwoCheckoutStatus: FC<TwoCheckoutStatusProps> = ({ status }) => {
  const getText = useGetText()
  const onCancel = useOnCancel()
  const { loading, success, failed } = PaymentState

  if (status != undefined && [loading, success, failed].includes(status)) {
    const renderIcon = (): JSX.Element | undefined => {
      if (status === loading) {
        return <ProgressIndicator />
      }
      if (status === success) {
        return <IconCheckmark filled color="hunterGreen6" size={48} />
      }
      if (status === failed) {
        return <IconMinusCircle size={48} color="red7" />
      }

      return undefined
    }

    return (
      <StyledContent>
        <StyledTitle>{getText(`two_checkout.${status}.title`)}</StyledTitle>
        <StyledIcon>{renderIcon()}</StyledIcon>
        <StyledMessage>{getText(`two_checkout.${status}.body`)}</StyledMessage>
        {status === failed ? (
          <Button onClick={() => onCancel()}>
            {getText('two_checkout.failed.button')}
          </Button>
        ) : null}
      </StyledContent>
    )
  }

  return null
}
