import React, { useCallback, useMemo, useRef, useState } from 'react'
import Button from '@veneer/core/dist/scripts/button'
import {
  useBillingAddress,
  useBillingInfo,
  useDispatch,
  useGetText,
  useLocale,
  useOnSave,
  usePaymentType,
  usePhcEventStatusPoller,
  useOnSaveButtonClick
} from '../../../hooks'
import { fetchBillingInfoAction } from '../../../actions'
import { PaymentType } from '../../../types'
import { Spinner } from '../../Spinner'
import { StyledPgsContainer, StyledPgsForm } from './styles'

export const PgsForm = () => {
  const getText = useGetText()
  const locale = useLocale()
  const paymentType = usePaymentType()
  const billingAddress = useBillingAddress()
  const {
    ipAddress,
    orderId,
    customerId,
    customField,
    creditCard,
    directDebit
  } = useBillingInfo() || {}
  const dispatch = useDispatch()
  const phcEventStatusPoller = usePhcEventStatusPoller()
  const onSave = useOnSave()
  const onSaveButtonClick = useOnSaveButtonClick()
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const iFrameRef = useRef<HTMLIFrameElement>(null)
  const iFrameId = 'pgs-iframe'
  const iFrameUrl = useMemo(
    () =>
      new URL(
        (paymentType === PaymentType.direct_debit
          ? directDebit?.iframeUrl
          : creditCard?.iframeUrl) as string
      ),
    [paymentType, creditCard, directDebit]
  )
  const iFrameHeight = useMemo(
    () => (paymentType === PaymentType.direct_debit ? '300px' : '460px'),
    [paymentType]
  )
  const postMessageToIframe = useCallback(
    (data) => {
      iFrameRef.current?.contentWindow?.postMessage(
        JSON.stringify(data),
        iFrameUrl.origin
      )
    },
    [iFrameRef, iFrameUrl]
  )
  const savePgsForm = useCallback(() => {
    const {
      firstName,
      lastName,
      street1,
      street2,
      city,
      state,
      zipCode,
      countryCode
    } = billingAddress || {}
    const customerInfo = {
      IPAddress: ipAddress,
      firstName,
      lastName
    }
    const billAddress = {
      address1: street1,
      address2: street2,
      city,
      state,
      zip: zipCode?.toUpperCase(),
      country: countryCode
    }
    const customFields = {
      ...JSON.parse(customField || '{}'),
      FirstName: firstName,
      LastName: lastName,
      Street1: street1,
      Street2: street2,
      City: city,
      State: state,
      Zip: zipCode?.toUpperCase(),
      CountryCode: countryCode,
      submitNoDataTouched: true
    }
    const additionalInfo = {
      orderID: orderId,
      customerID: customerId,
      culture: locale?.replace('_', '-')
    }
    postMessageToIframe({
      action: 'submit',
      customerInfo,
      billAddress,
      customField: JSON.stringify(customFields),
      ...additionalInfo
    })
  }, [
    billingAddress,
    customField,
    customerId,
    ipAddress,
    locale,
    orderId,
    postMessageToIframe
  ])

  const handleSave = useCallback(async () => {
    try {
      setSaveDisabled(true)
      savePgsForm()
      const phcEventStatus = await phcEventStatusPoller({ timeoutMS: 10000 })
      if (['success', 'successNoPaymentChanged'].includes(phcEventStatus)) {
        onSave()
      } else {
        postMessageToIframe({ action: 'showError' })
        await dispatch(fetchBillingInfoAction())
      }
    } catch (e) {
      if (e === 'timeout') {
        postMessageToIframe({ action: 'showError' })
        await dispatch(fetchBillingInfoAction())
      }
    } finally {
      setSaveDisabled(false)
    }
  }, [savePgsForm, phcEventStatusPoller, onSave, postMessageToIframe, dispatch])

  return (
    <>
      {iframeLoaded ? null : <Spinner />}
      <StyledPgsContainer show={iframeLoaded}>
        <StyledPgsForm
          id={iFrameId}
          title={iFrameId}
          key={iFrameId}
          ref={iFrameRef}
          src={iFrameUrl.toString()}
          name={iFrameId}
          iFrameHeight={iFrameHeight}
          onLoad={() => setIframeLoaded(true)}
        />
        <Button
          className={
            saveDisabled ? 'instant-ink-enroll-hpx-button-override' : ''
          }
          data-testid="save-button"
          data-analyticsid="SaveButton"
          disabled={saveDisabled}
          onClick={(event) => {
            event.persist()
            handleSave()
            onSaveButtonClick(event)
          }}
        >
          {getText('billing_form.continue')}
        </Button>
      </StyledPgsContainer>
    </>
  )
}
