import React from 'react'
import {
  useBillingAddressError,
  useErrorFields,
  useGetText,
  useTaxpayerDetailsError
} from '../../hooks'
import { StyledErrorMessageInline } from './styles'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'

export const ErrorMessageInline = () => {
  const getText = useGetText()
  const billingAddressError = useBillingAddressError()
  const taxpayerDetailsError = useTaxpayerDetailsError()
  const errorFields = useErrorFields()

  let message
  if (
    (billingAddressError &&
      ![401, 403].includes(billingAddressError?.response?.status as number)) ||
    (taxpayerDetailsError &&
      ![401, 403].includes(taxpayerDetailsError?.response?.status as number))
  ) {
    message = getText('error_message.something_went_wrong')
  } else if (errorFields.size > 0) {
    message = getText('error_message.complete_required')
  }

  if (message) {
    return (
      <StyledErrorMessageInline>
        <IconMinusCircle size={24} color="red7" filled />
        <span>{message}</span>
      </StyledErrorMessageInline>
    )
  }

  return null
}
