import { PaymentType } from './paymentType'
import { AxiosResponse } from 'axios'
import { BillingAddress } from './billingAddress'
import { BillingFlowSteps } from './billingFlowSteps'
import { TaxpayerDetails } from './taxpayerDetails'
import { ErrorResponse } from './errorResponse'

export enum BillingFormActionType {
  SET_ASSETS_PROVIDER = 'SET_ASSETS_PROVIDER',
  SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',
  SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS',
  SET_CONTAINTER_SIZE = 'SET_CONTAINTER_SIZE',
  SET_ITALY_TAX_FIELD = 'SET_ITALY_TAX_FIELD ',
  VALIDATE_BILLING_ADDRESS = 'VALIDATE_BILLING_ADDRESS',
  FETCH_SETTINGS = 'FETCH_SETTINGS',
  FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL',
  FETCH_BILLING_INFO = 'FETCH_BILLING_INFO',
  FETCH_BILLING_INFO_SUCCESS = 'FETCH_BILLING_INFO_SUCCESS',
  FETCH_BILLING_INFO_FAIL = 'FETCH_BILLING_INFO_FAIL',
  FETCH_TAXPAYER_DETAILS = 'FETCH_TAXPAYER_DETAILS',
  FETCH_TAXPAYER_DETAILS_SUCCESS = 'FETCH_TAXPAYER_DETAILS_SUCCESS',
  FETCH_TAXPAYER_DETAILS_FAIL = 'FETCH_TAXPAYER_DETAILS_FAIL',
  UPDATE_TAXPAYER_DETAIL_ACCOUNT_TYPE = 'UPDATE_TAXPAYER_DETAIL_ACCOUNT_TYPE',
  UPDATE_TAXPAYER_DETAIL = 'UPDATE_TAXPAYER_DETAIL',
  VALIDATE_TAXPAYER_DETAILS = 'VALIDATE_TAXPAYER_DETAILS',
  CLEAR_ITALY_TAXPAYER_DETAILS = 'CLEAR_ITALY_TAXPAYER_DETAILS',
  SAVE_TAXPAYER_DETAILS = 'SAVE_TAXPAYER_DETAILS',
  SAVE_TAXPAYER_DETAILS_SUCCESS = 'SAVE_TAXPAYER_DETAILS_SUCCESS',
  SAVE_TAXPAYER_DETAILS_FAIL = 'SAVE_TAXPAYER_DETAILS_FAIL',
  FETCH_BILLING_ADDRESS = 'FETCH_BILLING_ADDRESS',
  FETCH_BILLING_ADDRESS_SUCCESS = 'FETCH_BILLING_ADDRESS_SUCCESS',
  FETCH_BILLING_ADDRESS_FAIL = 'FETCH_BILLING_ADDRESS_FAIL',
  SAVE_BILLING_ADDRESS = 'SAVE_BILLING_ADDRESS',
  SAVE_BILLING_ADDRESS_SUCCESS = 'SAVE_BILLING_ADDRESS_SUCCESS',
  SAVE_BILLING_ADDRESS_FAIL = 'SAVE_BILLING_ADDRESS_FAIL',
  FETCH_SHIPPING_ADDRESS = 'FETCH_SHIPPING_ADDRESS',
  FETCH_SHIPPING_ADDRESS_SUCCESS = 'FETCH_SHIPPING_ADDRESS_SUCCESS',
  FETCH_SHIPPING_ADDRESS_FAIL = 'FETCH_SHIPPING_ADDRESS_FAIL',
  FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT',
  FETCH_USER_ACCOUNT_SUCCESS = 'FETCH_USER_ACCOUNT_SUCCESS',
  FETCH_USER_ACCOUNT_FAIL = 'FETCH_USER_ACCOUNT_FAIL',
  FETCH_PHC_EVENT_STATUS = 'FETCH_PHC_EVENT_STATUS',
  FETCH_PHC_EVENT_STATUS_SUCCESS = 'FETCH_PHC_EVENT_STATUS_SUCCESS',
  FETCH_PHC_EVENT_STATUS_FAIL = 'FETCH_PHC_EVENT_STATUS_FAIL',
  CREATE_PHC_EVENT_STATUS = 'CREATE_PHC_EVENT_STATUS',
  CREATE_PHC_EVENT_STATUS_SUCCESS = 'CREATE_PHC_EVENT_STATUS_SUCCESS',
  CREATE_PHC_EVENT_STATUS_FAIL = 'CREATE_PHC_EVENT_STATUS_FAIL',
  PUSH_CURRENT_STEP = 'PUSH_CURRENT_STEP',
  POP_CURRENT_STEP = 'POP_CURRENT_STEP'
}

export type BillingFormAction = {
  type: BillingFormActionType
  paymentType?: PaymentType
  containerSize?: string
  language?: string
  country?: string
  field?: string
  value?: string
  requiredFields?: string[]
  address?: BillingAddress
  response?: AxiosResponse
  taxpayerDetails?: TaxpayerDetails
  error?: ErrorResponse
  currentStep?: BillingFlowSteps
  ignoreEmptyFields?: boolean
  italyTaxFieldType?: 'taxId' | 'nonProfitTaxId'
}
