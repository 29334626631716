import React from 'react'
import { BillingFlowSteps } from '../../types'
import { useBillingFormState } from '../../hooks'
import { StepOne } from '../StepOne'
import { StepTwo } from '../StepTwo'

export const BillingFlow = () => {
  const { STEP_ONE, STEP_TWO } = BillingFlowSteps
  const { billingFlowStepHistory } = useBillingFormState()
  const currentStep = billingFlowStepHistory[billingFlowStepHistory.length - 1]

  let result: JSX.Element

  switch (currentStep) {
    case STEP_ONE:
      result = <StepOne />
      break
    case STEP_TWO:
      result = <StepTwo />
      break
  }

  return result
}
