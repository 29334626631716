import { useEffect, useRef, useReducer, useCallback } from 'react'

export declare type AsyncAction<A, S, R> = (
  dispatch: AsyncDispatch<A, S, R>,
  getState: () => S
) => Promise<R>

export declare type AsyncDispatch<A, S, R> = (
  action: A | AsyncAction<A, S, R>
) => Promise<R | undefined>

export const useAsyncReducer = <A, S, R>(
  reducer: (prevState: S, action: A) => S,
  initialState: S,
  initializer?: undefined
): [S, AsyncDispatch<A, S, R>] => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer)
  const stateRef = useRef(state)

  useEffect(() => {
    stateRef.current = state
  }, [state])

  const waitForUseEffect = () =>
    new Promise((resolve) => setTimeout(resolve, 0))

  const asyncDispatch: AsyncDispatch<A, S, R> = useCallback(
    async (action: A | AsyncAction<A, S, R>) => {
      if (typeof action === 'function') {
        return (action as AsyncAction<A, S, R>)(
          asyncDispatch,
          () => stateRef.current
        )
      }
      dispatch(action)
      await waitForUseEffect()
    },
    [dispatch]
  )

  return [state, asyncDispatch]
}
