import { useCallback, useEffect } from 'react'
import { useBillingFormState } from './useBillingFormState'
import { useDispatch } from './useDispatch'
import { fetchPhcEventStatusAction } from '../actions'
import { waitFor } from '../lib/waitFor'

export const usePhcEventStatus = () => {
  const { phcEventStatus, phcEventStatusLoading, phcEventStatusError } =
    useBillingFormState()
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      phcEventStatus === undefined &&
      !phcEventStatusLoading &&
      !phcEventStatusError
    ) {
      ;(async () => {
        try {
          await dispatch(fetchPhcEventStatusAction())
        } catch {
          // do nothing
        }
      })()
    }
  }, [phcEventStatus, phcEventStatusLoading, phcEventStatusError, dispatch])

  return phcEventStatus
}

export const usePhcEventStatusPoller = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({
      orderId,
      pickupId,
      intervalMS = 1000,
      timeoutMS = 60000
    }: {
      orderId?: string
      pickupId?: string
      intervalMS?: number
      timeoutMS?: number
    } = {}): Promise<string> =>
      waitFor(
        async () => {
          const response = await dispatch(
            fetchPhcEventStatusAction({ orderId, pickupId })
          )
          if (response?.status === 'waiting') {
            throw new Error('waiting')
          }
          return response?.status
        },
        intervalMS,
        timeoutMS
      ),
    [dispatch]
  )
}
