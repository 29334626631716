import { useGetText } from './useGetText'

export const useStateOptions = () => {
  const getText = useGetText()

  return JSON.parse(getText('state_dropdown.state_options')).map(
    (option: { [s: string]: string }) => {
      const [value, label] = Object.entries(option)[0]
      return { value, label }
    }
  )
}
