export const waitFor = <T>(
  fn: () => Promise<T>,
  intervalMS: number,
  timeoutMS: number
): Promise<T> =>
  new Promise((resolve, reject) => {
    let intervalId: number
    let timedOut = false
    let callbackPromise: Promise<T> | undefined = undefined

    const timeoutId = setTimeout(() => {
      clearTimeout(intervalId)
      timedOut = true
      if (callbackPromise === undefined) {
        reject('timeout')
      }
    }, timeoutMS)

    const callback = async () => {
      try {
        callbackPromise = fn()
        const result = await callbackPromise
        clearTimeout(timeoutId)
        resolve(result)
      } catch {
        if (timedOut) {
          reject('timeout')
        } else {
          intervalId = setTimeout(callback, intervalMS)
        }
      } finally {
        callbackPromise = undefined
      }
    }

    intervalId = setTimeout(callback, intervalMS)
  })
