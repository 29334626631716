import React from 'react'
import projectNames from '../src/configs/projectNames'
import { BillingForm, BillingFormProps } from '../src'
import { ThemeProvider as VeneerThemeProvider } from '@veneer/theme'
import { ThemeSettings } from '../src/types'

export default function Root(props: {
  properties: BillingFormProps & { themeSettings: ThemeSettings }
}) {
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <VeneerThemeProvider
        customSemantics={props.properties.themeSettings.customSemantics}
        mode={props.properties.themeSettings.mode}
        shape={props.properties.themeSettings.shape}
        density={props.properties.themeSettings.density}
      >
        <BillingForm {...props.properties} />
      </VeneerThemeProvider>
    </section>
  )
}
