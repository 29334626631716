import React, { FC } from 'react'
import { PayPalLogo } from '../PayPal/payPalLogo'
import { StyledCreditCardIcon, StyledDirectDebitIcon } from './styles'
import { PaymentType } from '../../types'

type PaymentIconProps = {
  paymentMethod: PaymentType
}

export const PaymentIcon: FC<PaymentIconProps> = ({ paymentMethod }) => {
  if (!paymentMethod) {
    return null
  }

  if (paymentMethod === 'credit_card') {
    return <StyledCreditCardIcon data-testid="credit-card-icon" size={32} />
  } else if (paymentMethod === 'direct_debit') {
    return <StyledDirectDebitIcon data-testid="direct-debit-icon" />
  } else {
    return <PayPalLogo data-testid="pay-pal-icon" marginTop={3} />
  }
}
