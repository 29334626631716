import styled from 'styled-components'

export const StyledErrorMessageInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;

  > span {
    margin-left: 8px;
    margin-block: 0;
  }
`
