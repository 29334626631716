import { BillingAddress } from '../../types'

export const billingAddressFixture = ({
  firstName = 'Bob',
  lastName = 'Smith',
  company = 'HP Inc',
  street1 = '456 Secondary St',
  street2 = 'APT 2',
  city = 'Los Angeles',
  state = 'CA',
  zipCode = '92127',
  countryCode = 'US'
} = {}): BillingAddress => ({
  firstName,
  lastName,
  company,
  street1,
  street2,
  city,
  state,
  zipCode,
  countryCode
})
