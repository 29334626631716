import { createContext } from 'react'
import { BillingFormAction, BillingFormState } from '../types'
import { AsyncDispatch } from '../hooks/useAsyncReducer'

type BillingFormContextType = {
  billingFormState: BillingFormState
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: AsyncDispatch<BillingFormAction, BillingFormState, any>
}

export const BillingFormContext = createContext<BillingFormContextType>(
  {} as BillingFormContextType
)
