import styled from 'styled-components'

export const StyledErrorMessagePage = styled.div`
  font-weight: bold;
  margin: 16px auto 0 auto;
  width: 100%;
`

export const StyledErrorPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 272px;

  svg {
    margin-bottom: 24px;
  }
`
