import React, { FC } from 'react'
import { useBillingInfo, useGetText, useOnSave } from '../../hooks'
import CheckMarkCircleIcon from '@veneer/core/dist/scripts/icons/icon_checkmark_circle'
import { PayPalLogo } from './payPalLogo'
import * as Styled from './styles'

export const PayPalSuccessContent: FC<{ closeModal: () => void }> = ({
  closeModal
}) => {
  const onSave = useOnSave()
  const getText = useGetText()
  const email = useBillingInfo()?.payPal?.email

  return (
    <Styled.ConfirmationModal data-testid="paypal-success-content">
      <CheckMarkCircleIcon size={48} />
      <PayPalLogo data-testid="pay-pal-icon" marginTop={8} />
      <Styled.Email data-testid="paypal-email">{email}</Styled.Email>
      <Styled.ConfirmationModalMessage>
        {getText('pay_pal_step.success.body')}
      </Styled.ConfirmationModalMessage>
      <Styled.ConfirmationModalButton
        onClick={() => {
          closeModal()
          setTimeout(onSave, 100)
        }}
        appearance="secondary"
      >
        {getText('pay_pal_step.success.done')}
      </Styled.ConfirmationModalButton>
    </Styled.ConfirmationModal>
  )
}
