import styled from 'styled-components'

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StyledTitle = styled.h2`
  margin: 0;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
`

export const StyledIcon = styled.div`
  padding: 8px 0;
`

export const StyledMessage = styled.div`
  padding: 8px 0 16px;
`
