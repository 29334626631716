import { TaxpayerDetails } from '../../types'

export const taxpayerDetailsFixture = ({
  accountType,
  companyName,
  taxId,
  nonProfitTaxId,
  lockedFields,
  pecEmailOrRecipientCode
}: TaxpayerDetails = {}): TaxpayerDetails => ({
  accountType,
  lockedFields,
  companyName,
  taxId,
  nonProfitTaxId,
  pecEmailOrRecipientCode
})
