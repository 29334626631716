export enum PaymentState {
  /**
   * 2CO different states
   * pending - waiting on customer to finish 2CO
   * loading - customer has finished 2CO, waiting on response from ComFE
   */
  pending = 'pending',
  loading = 'loading',
  success = 'success',
  failed = 'failed',
  canceled = 'canceled'
}
