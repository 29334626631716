export enum ErrorType {
  /**
   * Expired critical scope error
   */
  expired_critical_scope = 'expired_critical_scope',
  /**
   * Expired token error
   */
  expired_token = 'expired_token'
}
